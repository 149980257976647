import React, { useState, useEffect } from "react";
import TitleBar from "../titleBar";
import { useNavigate } from "react-router-dom";
import SubmitBox from "../submitBox";
import InputArea from "../inputArea";
import Box from "../box";
import { getLocalStateValue } from "../../../utils/helpers";
import InfoModal from "../infoModal";

// image imports
import HouseOne from "../../../assets/house_types/woning_five.png";
import HouseTwo from "../../../assets/house_types/woning_six.png";
import HouseThree from "../../../assets/house_types/woning_seven.png";

type HouseAreaProps = {
    globals?: {
        colors: {
            default: ""
        }
    },
    pageData?: {
        back_text: string,
        title: string,
        description: string,
        next_url: string,
        prev_url: string
    }
    updateAnswer?: (title: string, answer: string, url?: string) => void,
    storeAnswer?: (data: any, key: string) => void,
    modalInformation?: any
}

const HouseArea = ({ globals, pageData, updateAnswer = () => {}, storeAnswer = () => {}, modalInformation }: HouseAreaProps) => {
    const [ boxHover, setBoxHover ] = useState(0);
    const [ activeBox, setActiveBox ] = useState("");
    const [ inputValue, setInputValue ] = useState("");
    const [ modalState, setModalState ] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const localState = getLocalStateValue("3");
        if(localState) {
            if(localState?.oppervlakte_input) {
                setInputValue(localState?.oppervlakte_input);
            }
            else {
                setActiveBox(localState.answerNo);
            }
        }
    }, [])

    const goBack = () => {
        navigate(pageData?.prev_url || "")
    }

    const getImage = (answerIndex?: string) => {
        switch(answerIndex) {
            case "0":
                return HouseOne;
            case "1":
                return HouseTwo;
            case "2":
                return HouseThree;
        }
    }

    const onClick = (answer?: string, answerIndex?: string) => {
        const question = "oppervlakte";
        updateAnswer(question || "", answer || "", pageData?.next_url);
        storeAnswer({"answerNo": answerIndex, "oppervlakte": answer,"oppervlakte_input": inputValue, image: getImage(answerIndex)}, "3")
    }

    const onInputSubmit = () => {
        const question = "oppervlakte_input";
        updateAnswer(question || "", inputValue || "", pageData?.next_url);
        storeAnswer({"answerNo": "0", "oppervlakte": "Groot","oppervlakte_input": inputValue, image: ""}, "3");
    }

    const openInformationModal = () => {
        setModalState(true);
    }

    return(
        <div className = "w-full flex justify-center box-border">
            <div className = "container mx-auto flex-col justify-center box-border px-[16px] md:px-16 lg:px-32">
                <TitleBar back_text = {pageData?.back_text} infoColor = {globals?.colors?.default} title = {pageData?.title} description = {pageData?.description} goBack = {goBack} openInfoModal={openInformationModal} />
                <div className = "w-full flex flex-col mt-[10px] mb-[17px]">
                    <div className = "w-full flex sm:flex-row flex-col border-b-[1px] border-b-[#F0F5F5]">
                        <Box flex = {1} hasRightBorder text = "Groot" hoverValue = {1} hoverState = {boxHover} onHoverEvent = {setBoxHover} color = {globals?.colors?.default} onRoute = {onClick} image = {HouseOne} answerIndex = "0" isActive = {activeBox === "0"} />
                        <Box flex = {1} text = "Middel" hoverValue = {2} hoverState = {boxHover} onHoverEvent = {setBoxHover} color = {globals?.colors?.default} onRoute = {onClick} image = {HouseTwo} answerIndex = "1" isActive = {activeBox === "1"} />
                        <Box flex = {1} text = "Klein" hoverValue = {3} hoverState = {boxHover} onHoverEvent = {setBoxHover} color = {globals?.colors?.default} onRoute = {onClick} image = {HouseThree} answerIndex = "2" isActive = {activeBox === "2"} />
                    </div>
                </div>
                <SubmitBox isButtonActive = {inputValue?.length > 0} onSubmit = {onInputSubmit}>
                    <InputArea value = {inputValue} setValue = {setInputValue} title = "Bewoonbare oppervlakte in m²" description = "Ken je de exacte waarde van je bewoonbare oppervlakte? Vul deze dan hier in." marginLeft = {window.screen.width < 1000 ? "0px" : "138px"} enablePlaceholder = {false} />
                </SubmitBox>
            </div>
            <InfoModal modalInformation = {modalInformation} isModalOpen = {modalState} closeModal = {() => setModalState(false)} />
        </div>
    )
}

export default HouseArea;
