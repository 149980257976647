import React from "react";

type BoxProps = {
    hasRightBorder?: boolean,
    text?: string,
    image?: string,
    hoverValue: number,
    hoverState: number,
    onHoverEvent: (value: number) => void,
    color?: string,
    onRoute?: (answer?: string, answerIndex?: string) => void,
    alternativeRoute?: string;
    navigate?: (path: string) => void;
    flex: number,
    answerIndex?: string,
    isActive?: boolean
}

const Box = ({ hasRightBorder = false, text = "", image = "", hoverValue = 1, hoverState, onHoverEvent, color = "#398284", onRoute = () => {}, flex = 1, alternativeRoute = "", navigate = () => {}, answerIndex, isActive = false }: BoxProps) => {

    const useRoute = () => {
        if(alternativeRoute) {
            navigate(alternativeRoute)
        }
        else {
            onRoute(text || "", answerIndex);
        }
    }

    return(
        <div className = {`flex flex-col h-[225px] min-h-[225px] bg-white justify-center items-center cursor-pointer box-border px-[15px] ${hasRightBorder && "border-r-[1px] border-r-[#F0F5F5]"} duration-300 ease-in-out`} style = {{backgroundColor: (hoverValue === hoverState || isActive) ? color : "white", flex}} onMouseOver = {() => onHoverEvent(hoverValue)} onMouseLeave = {() => onHoverEvent(0)} onClick = {useRoute}>
            {image && (
                <img className = "h-[150px] object-contain object-center" alt = "house type" src = {image} />
            )}
            <p className = {`font-sans font-bold text-[22px] ${(hoverValue === hoverState || isActive) ? "text-white" : "text-black"} duration-300 ease-in-out text-center`}>{text}</p>
        </div>
    )
}

export default Box;