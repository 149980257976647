import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TitleBar from "../titleBar";
import Box from "../box";
import { getLocalStateValue } from "../../../utils/helpers";
import InfoModal from "../infoModal";

// image imports
import SolarBoiler from "../../../assets/water_heating_types/solarboiler.png"
import PumpBoiler from "../../../assets/water_heating_types/pumpboiler.png"
import ElectricBoiler from "../../../assets/water_heating_types/electricboiler.png"
import GasBoiler from "../../../assets/water_heating_types/gasboiler.png"

// page 7 image imports
import Gas from "../../../assets/heating_types/gas.png";
import Electricity from "../../../assets/heating_types/electricity.png";
import Pipe from "../../../assets/heating_types/pipe.png";
import Bonfire from "../../../assets/heating_types/bonfire.png";

type WarmwaterProps = {
    globals?: {
        colors: {
            default: ""
        }
    },
    pageData?: {
        back_text: string,
        title: string,
        description: string,
        next_url: string,
        prev_url: string
    },
    updateAnswer?: (title: string, answer: string, url?: string) => void,
    storeAnswer?: (data: any, key: string) => void,
    modalInformation?: any
}

const Warmwater = ({ globals, pageData, updateAnswer = () => {}, storeAnswer = () => {}, modalInformation }: WarmwaterProps) => {
    const [ hoverState, setHoverState ] = useState(0)
    const [ activeBox, setActiveBox ] = useState("")
    const [ modalState, setModalState ] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        const localState = getLocalStateValue("11");
        if (localState) {
            setActiveBox(localState.answerNo);
        }
    }, [])

    const getVerwarmingImage = () => {
        const localState = getLocalStateValue("6");
        if(localState) {
            switch(localState.answerNo) {
                case "0":
                    return Gas;
                case "1":
                    return Electricity;
                case "2":
                    return Pipe;
                case "3":
                    return Bonfire;
                default:
                    return Gas;
            }
        }
    }

    const goBack = () => {
        navigate(pageData?.prev_url || "")
    }

    const getImage = (answerIndex?: string) => {
        switch(answerIndex) {
            case "0":
                return Bonfire;
            case "1":
                return SolarBoiler;
            case "2":
                return PumpBoiler;
            case "3":
                return ElectricBoiler;
            case "4":
                return GasBoiler;
            default:
                return Bonfire;
        }
    }

    const onClick = (answer?: string, answerIndex?: string) => {
        const question = "warmwater";
        updateAnswer(question || "", answer || "", pageData?.next_url);
        storeAnswer({"answerNo": answerIndex, "warmwater": answer, image: getImage(answerIndex)}, "11")
    }

    const openInformationModal = () => {
        setModalState(true);
    }

    return(
        <div className = "w-full flex justify-center box-border">
            <div className = "container mx-auto flex-col justify-center box-border px-[16px] md:px-16 lg:px-32">
                <TitleBar back_text = {pageData?.back_text} infoColor = {globals?.colors?.default} title = {pageData?.title} description = {pageData?.description} goBack = {goBack} openInfoModal={openInformationModal} />
                <div className = "flex flex-col w-full mt-[10px] mb-[100px]">
                    <div className = "flex w-full flex-col md:flex-row border-b-[1px] border-b-[#F0F5F5]">
                        <Box flex = {1} hasRightBorder text = "Idem als verwarming" hoverValue = {1} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {getVerwarmingImage()} answerIndex = "0" isActive = {activeBox === "0"} />
                        <Box flex = {1} hasRightBorder text = "Zonneboiler" hoverValue = {2} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {SolarBoiler} answerIndex = "1" isActive = {activeBox === "1"} />
                        <Box flex = {1} hasRightBorder text = "Warmtepompboiler" hoverValue = {3} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {PumpBoiler} answerIndex = "2" isActive = {activeBox === "2"} />
                        <Box flex = {1} hasRightBorder text = "Elektrische boiler" hoverValue = {4} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {ElectricBoiler} answerIndex = "3" isActive = {activeBox === "3"} />
                    </div>
                    <div className = "flex w-full flex-col md:flex-row">
                        <Box flex = {1} hasRightBorder text = "Gasdoorstromer" hoverValue = {5} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {GasBoiler} answerIndex = "4" isActive = {activeBox === "4"} />
                        <div className = "flex flex-1 box-border px-[15px]" />
                        <div className = "flex flex-1 box-border px-[15px]" />
                        <div className = "flex flex-1 box-border px-[15px]" />
                    </div>
                </div>
            </div>
            <InfoModal modalInformation = {modalInformation} isModalOpen = {modalState} closeModal = {() => setModalState(false)} />
        </div>
    )
}

export default Warmwater;