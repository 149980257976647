import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TitleBar from "../titleBar";
import Box from "../box";
import { getLocalStateValue } from "../../../utils/helpers";
import InfoModal from "../infoModal";

// image imports
import EnergyOne from "../../../assets/energy/energy_one.png"
import EnergyTwo from "../../../assets/energy/energy_two.png"

type ZonnepanelenProps = {
    globals?: {
        colors: {
            default: ""
        }
    },
    pageData?: {
        back_text: string,
        title: string,
        description: string,
        next_url: string,
        prev_url: string
    },
    updateAnswer?: (title: string, answer: string, url?: string) => void,
    storeAnswer?: (data: any, key: string) => void,
    modalInformation?: any
}

const Zonnepanelen = ({ globals, pageData, updateAnswer = () => {}, storeAnswer = () => {}, modalInformation }: ZonnepanelenProps) => {
    const [ hoverState, setHoverState ] = useState(0);
    const [ activeBox, setActiveBox ] = useState("");
    const [ modalState, setModalState ] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const localState = getLocalStateValue("22");
        if (localState) {
            setActiveBox(localState.answerNo);
        }
    }, [])

    const goBack = () => {
        navigate(pageData?.prev_url || "")
    }

    const getImage = (answerIndex?: string) => {
        switch(answerIndex) {
            case "0":
                return EnergyOne;
            case "1":
                return EnergyTwo;
        }
    }

    const onClick = (answer?: string, answerIndex?: string) => {
        const question = "zonnepanelen";
        if(answerIndex === "0") {
            updateAnswer(question || "", answer || "", pageData?.next_url);
            storeAnswer({"answerNo": answerIndex, "zonnepanelen": answer, image: getImage(answerIndex)}, "22")
        }
        else {
            updateAnswer(question || "", answer || "", "/vraag/digitalemeter");
            storeAnswer({"answerNo": answerIndex, "zonnepanelen": answer, image: getImage(answerIndex)}, "22")
        }
    }

    const openInformationModal = () => {
        setModalState(true);
    }

    return(
        <div className = "w-full flex justify-center box-border">
            <div className = "container mx-auto flex-col justify-center box-border px-[16px] md:px-16 lg:px-32">
                <TitleBar back_text = {pageData?.back_text} infoColor = {globals?.colors?.default} title = {pageData?.title} description = {pageData?.description} goBack = {goBack} openInfoModal={openInformationModal} />
                <div className = "flex flex-col w-full mt-[10px] mb-[100px]">
                    <div className = "flex w-full flex-col md:flex-row">
                        <Box flex = {1} hasRightBorder text = "Wel zonnepanelen" hoverValue = {1} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {EnergyOne} answerIndex = "0" isActive = {activeBox === "0"} />
                        <Box flex = {1} hasRightBorder text = "Geen zonnepanelen" hoverValue = {2} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {EnergyTwo} answerIndex = "1" isActive = {activeBox === "1"} />
                        <div className = "flex flex-1" />
                    </div>
                </div>
            </div>
            <InfoModal modalInformation = {modalInformation} isModalOpen = {modalState} closeModal = {() => setModalState(false)} />
        </div>
    )
}

export default Zonnepanelen;