import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TitleBar from "../titleBar";
import Box from "../box";
import { getLocalStateValue } from "../../../utils/helpers";
import InfoModal from "../infoModal";

// image imports here
import ElectricOne from "../../../assets/electricity_types/electric_one.png"
import ElectricTwo from "../../../assets/electricity_types/electric_two.png"
import ElectricThree from "../../../assets/electricity_types/electric_three.png"
import ElectricFour from "../../../assets/electricity_types/electric_four.png"
import ElectricFive from "../../../assets/electricity_types/electric_five.png"

type ElektriciteitProps = {
    globals?: {
        colors: {
            default: ""
        }
    },
    pageData?: {
        back_text: string,
        title: string,
        description: string,
        next_url: string,
        prev_url: string
    }
    updateAnswer?: (title: string, answer: string, url?: string) => void,
    storeAnswer?: (data: any, key: string) => void,
    modalInformation?: any
}

const Elektriciteit = ({ globals, pageData, updateAnswer = () => {}, storeAnswer = () => {}, modalInformation }: ElektriciteitProps) => {
    const [ hoverState, setHoverState ] = useState(0);
    const [ activeBox, setActiveBox ] = useState("");
    const [ modalState, setModalState ] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const localState = getLocalStateValue("7");
        if (localState) {
            setActiveBox(localState.answerNo);
        }
    }, [])

    const goBack = () => {
        navigate(pageData?.prev_url || "")
    }

    const getImage = (answerIndex?: string) => {
        switch(answerIndex) {
            case "0":
                return ElectricOne;
            case "1":
                return ElectricTwo;
            case "2":
                return ElectricThree;
            case "3":
                return ElectricFour;
            case "4":
                return ElectricFive;
        }
    }

    const onClick = (answer?: string, answerIndex?: string) => {
        const question = "elektriciteit";
        let nextRoute = pageData?.next_url
        if(answerIndex === "0") {
            nextRoute = "/vraag/verwarmingleefruimtes";
        }
        updateAnswer(question || "", answer || "", nextRoute);
        storeAnswer({"answerNo": answerIndex, "elektriciteit": answer, image: getImage(answerIndex)}, "7");
    }

    const openInformationModal = () => {
        setModalState(true);
    }

    return(
        <div className = "w-full flex justify-center box-border">
            <div className = "container mx-auto flex-col justify-center box-border px-[16px] md:px-16 lg:px-32">
                <TitleBar back_text = {pageData?.back_text} infoColor = {globals?.colors?.default} title = {pageData?.title} description = {pageData?.description} goBack = {goBack} openInfoModal={openInformationModal} />
                <div className = "flex flex-col w-full mt-[10px] mb-[100px]">
                    <div className = "flex w-full flex-col md:flex-row border-b-[1px] border-b-[#F0F5F5]">
                        <Box flex = {1} hasRightBorder text = "Elektrische toestellen" hoverValue = {1} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {ElectricOne} answerIndex = "4" isActive = {activeBox === "0"} />
                        <Box flex = {1} hasRightBorder text = "Warmtepomp lucht/lucht" hoverValue = {2} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {ElectricTwo} answerIndex = "6" isActive = {activeBox === "1"} />
                        <Box flex = {1} hasRightBorder text = "Warmtepomp lucht/water" hoverValue = {3} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {ElectricThree} answerIndex = "7" isActive = {activeBox === "2"} />
                        <Box flex = {1} hasRightBorder text = "Warmtepomp water/water" hoverValue = {4} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {ElectricFour} answerIndex = "8" isActive = {activeBox === "3"} />
                    </div>
                    <div className = "flex w-full flex-col md:flex-row border-b-[1px] border-b-[#F0F5F5]">
                        <Box flex = {1} hasRightBorder text = "Warmtepomp bodem/water" hoverValue = {5} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {ElectricFive} answerIndex = "9" isActive = {activeBox === "4"} />
                        <div className = "flex flex-1 box-border px-[15px]" />
                        <div className = "flex flex-1 box-border px-[15px]" />
                        <div className = "flex flex-1 box-border px-[15px]" />
                    </div>
                </div>
            </div>
            <InfoModal modalInformation = {modalInformation} isModalOpen = {modalState} closeModal = {() => setModalState(false)} />
        </div>
    )
}

export default Elektriciteit;