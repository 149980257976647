import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// component imports 
// import Layout from "../../components/layout";
import ProgressBar from "../../components/form/progressbar";
import pageData from "./pageData";
import axios from "axios";
import Cookies from "js-cookie";

const formPages = [...pageData];

type FormProps = {
    globals: {
        colors: {
            default: string
        }
    }
}

type moreInformationItemType = {
    id: string,
    key: string,
    value: string
}

type moreInformationListType = moreInformationItemType[]


const Form = ({ globals }: FormProps ) => {
    const [ pageSelector, setPageSelector ] = useState("");
    const [ answerData, setAnswerData ] = useState({});
    const [ moreInformationModalList, setMoreInformationModalList ] = useState<moreInformationItemType[]>([]);

    const { path } = useParams();
    const navigate = useNavigate();
    const localState = JSON.parse(localStorage.getItem("localState") || "{}");

    useEffect(() => {
        if(path) {
            setPageSelector(path)
        }
    }, [path])

    useEffect(() => {
        if(localState) {
            setAnswerData(localState);
        }
        axios.get(process.env.REACT_APP_BACKEND_URL + "/page/7").then(response => {
            console.log(response.data[0].pageContent);
            setMoreInformationModalList(response.data[0].pageContent);
        }).catch(error => console.log(error))
    }, [])

    function updateAnswer(title: string, answer: string, url?: string) {
        if(Cookies.get("userId")) {
            axios.post(process.env.REACT_APP_BACKEND_URL + "/session/answer", {
                question_text: title,
                selected_answer: answer,
                sessionId: Cookies.get("userId")
            }).then(response => {
                navigate(url || "/");
            }).catch(error => console.log(error))
        }
        else {
            console.log("No user id found in cookies")
        }
    }

    function storeAnswerData(data: any, key: string) {
        const newAnswerData = {
            ...answerData,
            [key]: {...data}
        }
        setAnswerData(newAnswerData);
        localStorage.setItem("localState", JSON.stringify(newAnswerData));
    }

    const getModalInformation = (key: string) => {
        if(key) {
            let modalInformation = {};
            moreInformationModalList.forEach(item => {
                if(item.key.includes(key)) {
                    let objectKey = item.key.split(key)[1];
                    modalInformation = {...modalInformation, [objectKey]: item.value} 
                }
            })
            return modalInformation;
        }
        else {
            return null;
        }
    }
 
    return(
        // <Layout disableFooter backgroundColor="#f0f5f5">
            <div>
                <ProgressBar pageData = {formPages?.find(page => page.url === pageSelector) || {}} color = {globals.colors.default} />
                {formPages.map(pageData => {
                    if(pageData.url === pageSelector) {
                        const clonedElement = React.cloneElement(pageData.component, {
                            globals: globals || {},
                            updateAnswer: updateAnswer,
                            pageData,
                            storeAnswer: storeAnswerData,
                            userId: Cookies.get("userId"),
                            modalInformation: getModalInformation(pageData.modal_key)
                        })
                        return clonedElement;
                    }
                })}
            </div>
        // </Layout>
    )
}

export default Form;