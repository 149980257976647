import { useEffect, useState } from "react"
import Layout from "../../components/layout";
import TermsAndConditions from '../../components/privacyPolicy'

const Simulations = () => {
    return (
        <Layout>
            <div className='px-4 md:px-8 lg:px-16 xl:px-32 py-8'>
                <TermsAndConditions />
            </div>
        </Layout>
    )
}

export default Simulations
