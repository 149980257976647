import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TitleBar from "../titleBar";
import Box from "../box";
import { getLocalStateValue } from "../../../utils/helpers";
import InfoModal from "../infoModal";

type VerwarmingleefruimtesProps = {
    globals?: {
        colors: {
            default: ""
        }
    },
    pageData?: {
        back_text: string,
        title: string,
        description: string,
        next_url: string,
        prev_url: string
    },
    updateAnswer?: (title: string, answer: string, url?: string) => void,
    storeAnswer?: (data: any, key: string) => void,
    modalInformation?: any
}

const Verwarmingleefruimtes = ({ globals, pageData, updateAnswer = () => {}, storeAnswer = () => {}, modalInformation }: VerwarmingleefruimtesProps) => {
    const [ hoverState, setHoverState ] = useState(0)
    const [ activeBox, setActiveBox ] = useState("")
    const [ modalState, setModalState ] = useState(false)
    
    const navigate = useNavigate();

    useEffect(() => {
        const localState = getLocalStateValue("9");
        if (localState) {
            setActiveBox(localState.answerNo);
        }
    }, [])

    const goBack = () => {
        const route = checkPreviousAnswers();
        navigate(route || "")
    }

    const checkPreviousAnswers = () => {
        const verwarmingAnswer = getLocalStateValue("6");
        const verwarmingSubAnswer = getLocalStateValue("7");
        const { verwarming } = verwarmingAnswer;
        const { answerNo } = verwarmingSubAnswer;

        if(verwarming === "gas" && answerNo === "0") {
            return "/vraag/gas";
        }
        else if(verwarming === "elektriciteit" && answerNo === "4") {
            return "/vraag/elektriciteit";
        }
        else if(verwarming === "stookolie" && answerNo === "10") {
            return "/vraag/stookolie";
        }
        else if(verwarming === "eenanderebron" && (answerNo === "15" || answerNo === "16") ) {
            return "/vraag/eenanderebron";
        }
        else {
            return pageData?.prev_url;
        }
    }

    const getBackButtonText = () => {
        const verwarmingAnswer = getLocalStateValue("6");
        const verwarmingSubAnswer = getLocalStateValue("7");
        const { verwarming } = verwarmingAnswer;
        const { answerNo } = verwarmingSubAnswer;

        if(verwarming === "gas" && answerNo === "0") {
            return "Gas";
        }
        else if(verwarming === "elektriciteit" && answerNo === "4") {
            return "Elektriciteit";
        }
        else if(verwarming === "stookolie" && answerNo === "10") {
            return "Stookolie";
        }
        else if(verwarming === "eenanderebron" && (answerNo === "15" || answerNo === "16") ) {
            return "Een andere bron";
        }
        else {
            return pageData?.back_text;
        }
    }

    const onClick = (answer?: string, answerIndex?: string) => {
        const question = "verwarming_leefruimtes";
        updateAnswer(question || "", answer || "", pageData?.next_url);
        storeAnswer({"answerNo": answerIndex, "verwarming_leefruimtes": answer, image: ""}, "9");
    }

    const openInformationModal = () => {
        setModalState(true);
    }

    return(
        <div className = "w-full flex justify-center box-border">
            <div className = "container mx-auto flex-col justify-center box-border px-[16px] md:px-16 lg:px-32">
                <TitleBar back_text = {getBackButtonText()} infoColor = {globals?.colors?.default} title = {pageData?.title} description = {pageData?.description} goBack = {goBack} openInfoModal={openInformationModal} />
                <div className = "flex flex-col w-full mt-[10px] mb-[100px]">
                    <div className = "flex w-full flex-col md:flex-row border-b-[1px] border-b-[#F0F5F5]">
                        <Box flex = {1} hasRightBorder text = "Altijd" hoverValue = {1} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} answerIndex = "0" isActive = {activeBox === "0"} />
                        <Box flex = {1} hasRightBorder text = "Vooral buiten de kantooruren" hoverValue = {2} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} answerIndex = "1" isActive = {activeBox === "1"} />
                        <Box flex = {1} hasRightBorder text = "Zelden of enkel als het heel koud is" hoverValue = {3} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} answerIndex = "2" isActive = {activeBox === "2"} />
                        <Box flex = {1} hasRightBorder text = "Nooit" hoverValue = {4} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} answerIndex = "3" isActive = {activeBox === "3"} />
                    </div>
                </div>
            </div>
            <InfoModal modalInformation = {modalInformation} isModalOpen = {modalState} closeModal = {() => setModalState(false)} />
        </div>
    )
}

export default Verwarmingleefruimtes;