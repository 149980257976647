import React, { FC, useState } from "react";

interface CookiesInterface {
    setCookieBanner?: any;
    acceptAllCookies?: any;
    acceptCookies?: any;
}

const Cookies: FC<CookiesInterface> = ({
                                           setCookieBanner,
                                           acceptAllCookies,
                                           acceptCookies,
                                       }) => {
    return (
        <div style={{ zIndex: 1000 }} className="fixed  bottom-10 left-[0] right-[0] md:right-[initial] md:left-10  w-[100%] md:w-[50%] lg:w-[40%] xl:w-[30%] px-4 md:px-0">
            <div className="transition ease-in-out rounded overflow-hidden shadow-xl border bg-white">
                <div className="px-6 py-4">
                    <p className="mt-4 md:text-xs xl:text-sm 2xl:text-base text-gray-900">
                        We gebruiken cookies om de gebruikerservaring te verbeteren en
                        websiteverkeer te analyseren. Door op "Accepteren", te klikken, gaat
                        u akkoord met het gebruik van cookies op onze website zoals
                        beschreven in ons Cookiebeleid.
                    </p>
                    <div className=" pb-3 pt-4">
                        <button
                            className="px-6 py-2  bg-peer text-white text-[14px] w-full "
                            onClick={() => {
                                acceptAllCookies();
                                setCookieBanner("false");
                            }}
                        >
                            Accepteren
                        </button>
                        <button
                            className="px-6 py-2  bg-peer text-white my-3 text-[14px] w-full "
                            onClick={() => {
                                acceptCookies({
                                    preferences: true,
                                    necessary: true,
                                });
                                setCookieBanner("false");
                            }}
                        >
                            Enkel essentiële cookies accepteren
                        </button>
                        <button
                            className="px-6 py-2  bg-peer text-white text-[14px] w-full "
                            onClick={() => {
                                setCookieBanner("false");
                            }}
                        >
                            Afwijzen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cookies;
