import { openSession,getSession,updateSession } from "../context/actions/session";
import Cookies from "js-cookie"
import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';

type CookieTypes = {
  userId: string,
  sessionData: {
      id: string,
      start_session: string,
      latest_update: string,
      email: string | null,
      address: string | null,
      news_letter_subscription: boolean | null,
      current_consumption: null,
      future_consumption: null,
      epc_score: null,
      createdAt: string | null,
      updatedAt: string | null,
      deletedAt: string | null
  }
}

// method to create new Session or get existing one
export const Sessions = (cookies : CookieTypes, setCookies: React.Dispatch<React.SetStateAction<CookieTypes>>,dispatch: any) => {
  let userExist = 0;
  if(cookies?.userId) {
    userExist = parseInt(cookies?.userId)
  }
  else {
    userExist = parseInt(Cookies.get("userId") || "0");
  }

  if (userExist) {
    const sessionData = cookies?.sessionData;
    const start_session = new Date (sessionData?.start_session);
    const sessionCreatedSince = moment().diff(start_session, 'hours')

    //if sessions is older than a day, then create new one
    if(sessionCreatedSince > 24){
      let sessionData: any = {};
      openSession()
        .then((response) => {
          sessionData = response.data;
          dispatch({
            type: "SET_SESSION_DATA",
            payload: sessionData,
          });
          Cookies.set("userId", sessionData.id, {expires: 1})
          Cookies.set("sessionData", JSON.stringify(sessionData), {expires: 1})
          const cookieObject = {
            userId: sessionData?.id,
            sessionData: sessionData
          }
        })
        .catch((error) => {
          console.log("Error opening session",error);
        });
    }else{
      const userId = Cookies.get("userId");
      const sessionData = JSON.parse(Cookies.get("sessionData")!);
      const cookieObject: CookieTypes = {
        userId: userId || "0",
        sessionData: sessionData
      }
      setCookies(cookieObject)
      // getSession(userExist).then((response)=>{
      //   let sessionData: any = {};
      //   sessionData = response.data.data.session[0];
      //   dispatch({
      //     type: "SET_SESSION_DATA",
      //     payload: sessionData,
      //   });
      //   Cookies.set("userId", sessionData.id, {expires: 1})
      //   Cookies.set("sessionData", JSON.stringify(sessionData), {expires: 1})
      //   const cookieObject = {
      //     userId: sessionData?.id,
      //     sessionData: sessionData
      //   }
      //   setCookies(cookieObject)
      // }).catch((error) => {
      //     console.log("Error getting session",error);
      // });
    }
  } else {
    // if the the visitor doesn't exist, then we call backend API the create a visitor token.
    let sessionData: any = {};
    openSession()
      .then((response) => {
        sessionData = response.data;
        dispatch({
          type: "SET_SESSION_DATA",
          payload: sessionData,
        });
        dispatch({
          type: "",
          payload: sessionData,
        });
        Cookies.set("userId", sessionData.id, {expires: 1})
        Cookies.set("sessionData", JSON.stringify(sessionData), {expires: 1})
        const cookieObject = {
          userId: sessionData?.id,
          sessionData: sessionData
        }
        setCookies(cookieObject)
      })
      .catch((error) => {
        console.log("error opening session",error);
      });
  }
};

// method to update a Session and start form

export const SessionsUpdate = (user: number, payload: object, cookies : any, setCookies : any ,dispatch: any) => {
  let sessionData: any = {};
  dispatch({
    type: "SET_REDIRECT_FORM",
    payload: true,
  });
  // updateSession(user,payload).then((response) => {
  //   let sessionData = response.data.session;
  //   dispatch({
  //     type: "SET_SESSION_DATA",
  //     payload: sessionData,
  //   });
    
  //   setCookies("userId",sessionData.id)
  //   setCookies("sessionData",sessionData)
  // })
  // .catch((error) => {
  //   console.log("Error updating session",error);
  // });
};


