import React from "react";
import { MdClose } from "react-icons/md";
import { PrimaryButton } from "../../shared/buttons/peer_button";

type modalInformationType = {
    title: string,
    button_text: string,
    content: string,
    link_text: string,
    link_url: string,
    subtitle: string,
    subcontent: string
}

type InfoModalType = {
    isModalOpen?: boolean,
    closeModal?: () => void,
    modalInformation?: modalInformationType
}

const InfoModal = ({ isModalOpen = false, closeModal = () => {}, modalInformation }: InfoModalType) => {
    const openLink = () => {
        if(modalInformation?.link_url) {
            window.open(modalInformation.link_url, '_blank');
        }
    }
    return(
        <div className = "w-screen h-screen fixed top-0 left-0 flex justify-center items-center animate-fade-in-top ease-in-out" style = {{display: isModalOpen ? "flex" : "none"}}>
            <div className = "w-full h-full bg-[#000000]/[0.3] absolute left-0 top-0" />
            <div className = "max-w-[90%] min-w-[300px] max-h-[90%] overflow-y-auto box-border pt-[20px] rounded-[5px] bg-white flex flex-col relative">
                <div className = "flex w-full justify-end box-border px-[16px]">
                    <div onClick = {closeModal}>
                        <MdClose size = "24px" color = "black" className = "cursor-pointer" />
                    </div>
                </div>
                {modalInformation && (
                    <>
                        <div className = "flex w-full flex-col box-border px-[16px]">
                            <p className = "font-Source font-bold text-[16px] text-black mt-[25px]" dangerouslySetInnerHTML={{ __html: modalInformation.title }} />
                            <p className = "font-Source font-regular text-[16px] text-black mt-[25px]" dangerouslySetInnerHTML={{ __html: modalInformation.content }} />
                            <p className = "font-Source font-bold text-[16px] text-black mt-[25px]" dangerouslySetInnerHTML={{ __html: modalInformation.subtitle }} />
                            <p className = "font-Source font-regular text-[16px] text-black mt-[25px]" dangerouslySetInnerHTML={{ __html: modalInformation.subcontent }} />
                            <p className = "font-Source font-bold text-[16px] text-primary mt-[25px] cursor-pointer w-fit" onClick = {openLink} dangerouslySetInnerHTML={{ __html: modalInformation.link_text }} />
                        </div>
                        <div className = "flex w-full box-border p-[16px] border-t-[2px] border-t-primary mt-[25px]">
                            <PrimaryButton content = {modalInformation.button_text} onClick = {closeModal} />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default InfoModal;