import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TitleBar from "../titleBar";
import Box from "../box";
import { getLocalStateValue } from "../../../utils/helpers";
import InfoModal from "../infoModal";

type BewonersProps = {
    globals?: {
        colors: {
            default: ""
        }
    },
    pageData?: {
        back_text: string,
        title: string,
        description: string,
        next_url: string,
        prev_url: string
    }
    updateAnswer?: (title: string, answer: string, url?: string) => void,
    storeAnswer?: (data: any, key: string) => void,
    modalInformation?: any
}

const Bewoners = ({ globals, pageData, updateAnswer = () => {}, storeAnswer = () => {}, modalInformation }: BewonersProps) => {
    const [ hoverState, setHoverState ] = useState(0);
    const [ activeBox, setActiveBox ] = useState("");
    const [ modalState, setModalState ] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const localState = getLocalStateValue("5");
        if (localState) {
            setActiveBox(localState.answerNo);
        }
    }, [])

    const goBack = () => {
        navigate(pageData?.prev_url || "")
    }

    const onClick = (answer?: string, answerIndex?: string) => {
        const question = "bewoners";
        updateAnswer(question || "", answer || "", pageData?.next_url);
        storeAnswer({"answerNo": answerIndex, "bewoners": answer, image: ""}, "5")
    }

    const openInformationModal = () => {
        setModalState(true);
    }

    return(
        <div className = "w-full flex justify-center box-border">
            <div className = "container mx-auto flex-col justify-center box-border px-[16px] md:px-16 lg:px-32">
                <TitleBar back_text = {pageData?.back_text} infoColor = {globals?.colors?.default} title = {pageData?.title} description = {pageData?.description} goBack = {goBack} openInfoModal={openInformationModal} />
                <div className = "w-full flex flex-col mt-[10px] mb-[100px]">
                    <div className = "w-full flex flex-col md:flex-row border-b-[1px] border-b-[#F0F5F5]">
                        <Box flex = {1} hasRightBorder text = "1 personen" hoverValue = {1} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} answerIndex = "0" isActive = {activeBox === "0"} />
                        <Box flex = {1} hasRightBorder text = "2 personen" hoverValue = {2} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} answerIndex = "1" isActive = {activeBox === "1"} />
                        <Box flex = {1} hasRightBorder text = "3 personen" hoverValue = {3} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} answerIndex = "2" isActive = {activeBox === "2"} />
                        <Box flex = {1} hasRightBorder text = "4 personen" hoverValue = {4} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} answerIndex = "3" isActive = {activeBox === "3"} />
                        <Box flex = {1} hasRightBorder text = "5 personen" hoverValue = {5} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} answerIndex = "4" isActive = {activeBox === "4"} />
                    </div>
                    <div className = "w-full flex flex-col md:flex-row border-b-[1px] border-b-[#F0F5F5]">
                        <Box flex = {1} hasRightBorder text = "6 personen" hoverValue = {6} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} answerIndex = "5" isActive = {activeBox === "5"} />
                        <Box flex = {1} hasRightBorder text = "7 personen" hoverValue = {7} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} answerIndex = "6" isActive = {activeBox === "6"} />
                        <Box flex = {1} hasRightBorder text = "8 personen" hoverValue = {8} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} answerIndex = "7" isActive = {activeBox === "7"} />
                        <Box flex = {1} hasRightBorder text = "9 of meer personen" hoverValue = {9} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} answerIndex = "8" isActive = {activeBox === "8"} />
                        <div className = "flex flex-1 box-border px-[15px]" />
                    </div>
                </div>
            </div>
            <InfoModal modalInformation = {modalInformation} isModalOpen = {modalState} closeModal = {() => setModalState(false)} />
        </div>
    )
}

export default Bewoners;