import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TitleBar from "../titleBar";
import Box from "../box";
import { getLocalStateValue } from "../../../utils/helpers";
import InfoModal from "../infoModal";

// page imports
import Radiator from "../../../assets/heating_element_types/radiator.png"
import Floor from "../../../assets/heating_element_types/floor.png"
import Convection from "../../../assets/heating_element_types/convection.png"
import Combination from "../../../assets/heating_element_types/combination.png";

type VerwarmingselementenProps = {
    globals?: {
        colors: {
            default: ""
        }
    },
    pageData?: {
        back_text: string,
        title: string,
        description: string,
        next_url: string,
        prev_url: string
    },
    updateAnswer?: (title: string, answer: string, url?: string) => void,
    storeAnswer?: (data: any, key: string) => void,
    modalInformation?: any
}

const Verwarmingselementen = ({ globals, pageData, updateAnswer = () => {}, storeAnswer = () => {}, modalInformation }: VerwarmingselementenProps) => {
    const [ hoverState, setHoverState ] = useState(0);
    const [ activeBox, setActiveBox ] = useState("");
    const [ modalState, setModalState ] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const localState = getLocalStateValue("8");
        if (localState) {
            setActiveBox(localState.answerNo);
        }
    }, [])

    const goBack = () => {
        const localState = getLocalStateValue("6");
        if (localState) {
            navigate("/vraag/" + localState.verwarming);
        }
        else {
            navigate(pageData?.prev_url || "")
        }
    }

    function getPreviousLink() {
        const localState = getLocalStateValue("6");
        if (localState) {
            if(localState.verwarming === "eenanderebron") {
                return `Een Andere Bron`;
            }
            else {
                return `${localState.verwarming}`;
            }
        }
        return pageData?.prev_url;
    }

    const getImage = (answerIndex?: string) => {
        switch(answerIndex) {
            case "0":
                return Radiator;
            case "1":
                return Floor;
            case "2":
                return Convection;
            case "3":
                return Combination;
            default:
                return Radiator;
        }
    }

    const onClick = (answer?: string, answerIndex?: string) => {
        const question = "verwarmingselementen";
        updateAnswer(question || "", answer || "", pageData?.next_url);
        storeAnswer({"answerNo": answerIndex, "verwarmingselementen": answer, image: getImage(answerIndex)}, "8");
    }

    const openInformationModal = () => {
        setModalState(true);
    }

    return(
        <div className = "w-full flex justify-center box-border">
            <div className = "container mx-auto flex-col justify-center box-border px-[16px] md:px-16 lg:px-32">
                <TitleBar back_text = {getPreviousLink()} infoColor = {globals?.colors?.default} title = {pageData?.title} description = {pageData?.description} goBack = {goBack} openInfoModal={openInformationModal} />
                <div className = "flex flex-col w-full mt-[10px] mb-[100px]">
                    <div className = "flex w-full flex-col md:flex-row border-b-[1px] border-b-[#F0F5F5]">
                        <Box flex = {1} hasRightBorder text = "Radiatoren" hoverValue = {1} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {Radiator} answerIndex = "0" isActive = {activeBox === "0"} />
                        <Box flex = {1} hasRightBorder text = "Vloer- en/of wandverwarming" hoverValue = {2} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {Floor} answerIndex = "1" isActive = {activeBox === "1"} />
                        <Box flex = {1} hasRightBorder text = "Convectoren" hoverValue = {3} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {Convection} answerIndex = "2" isActive = {activeBox === "2"} />
                        <Box flex = {1} hasRightBorder text = "Een combinatie" hoverValue = {4} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {Combination} isActive = {activeBox === "3"} />
                    </div>
                </div>
            </div>
            <InfoModal modalInformation = {modalInformation} isModalOpen = {modalState} closeModal = {() => setModalState(false)} />
        </div>
    )
}

export default Verwarmingselementen;