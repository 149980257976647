import React, { useState } from "react";
import HouseOne from "../../assets/house_types/woning_five.png";
import Pieces from "../../assets/pieces.png";
type PointsProps = {
    
    pageData?: {
        back_text: string,
        title: string,
        next_url: string,
        prev_url: string
    }
}








const Points = ({pageData }: PointsProps) => {

    return (

<div className="mx-auto flex flex-col mt-[53px]">
    <div className="flex flex-col w-full">
        <div className="flex flex-col items-center w-full bg-white ">
            <div className="w-full md:flex md:flex-row ml-8.5 mt-5 items-center">
                <div className="ml-12 w-[10px] h-[39px] bg-[#00AFAA] hidden md:block" />
                <p className="font-sans font-bold text-black text-[36px] ml-[30px]">Maak je huis klaar voor morgen</p>
            </div>
            <div className="w-full md:flex md:flex-row items-center mt-[10px] mb-[47px]">
                <div className="flex flex-1 flex-row md:justify-center items-center box-border px-[35px]">
                    <p className="font-sans font-bold text-black text-[50px] leading-[60px]">1.</p>
                    <p className="font-sans font-medium text-black text-[14px] ml-[10px]">Kies de verbeteringen die voor jou belangrijk zijn</p>
                </div>
                <div className="flex flex-1 flex-row md:justify-center items-center box-border px-[35px]">
                    <p className="font-sans font-bold text-black text-[50px] leading-[60px]">2.</p>
                    <p className="font-sans font-medium text-black text-[14px] ml-[10px]">Bekijk de premies, besparingen en investeringskosten</p>
                </div>
                <div className="flex flex-1 flex-row md:justify-center items-center box-border px-[35px]">
                    <p className="font-sans font-bold text-black text-[50px] leading-[60px]">3.</p>
                    <p className="font-sans font-medium text-black text-[14px] ml-[10px]">Tevreden? Voeg ze toe aan je persoonlijk stappenplan</p>
                </div>
                <div className="flex flex-1 flex-row md:justify-center items-center box-border px-[35px]">
                    <p className="font-sans font-bold text-black text-[50px] leading-[60px]">4.</p>
                    <p className="font-sans font-medium text-black text-[14px] ml-[10px]">Ontvang je plan via mail</p>
                </div>
            </div>
        </div>
    </div>
</div>

)
};
export default Points;