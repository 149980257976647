import React, {FC} from "react";
import greyDownload from "../../assets/icons/pdf.svg";
import colorDownload from "../../assets/icons/pdf-color.svg";
import plus from "../../assets/icons/plus.png";
import filterIcon from "../../assets/icons/filter-results-button.png";


interface PeerInterface {
    icon?: string,
    content: string,
    classes?: string,
    iconClasses?: string,
    disabled?: boolean
    defaultClasses?: string,
    onClick: (event: React.MouseEvent<HTMLElement>)=> void
}

export const PeerButton: FC<PeerInterface> = ({
                                                    icon = null,
                                                    content = '',
                                                    disabled = false,
                                                    classes = "",
                                                    defaultClasses = "px-6 py-2 md:px-9 md:py-3 lg:px-12 lg:py-4",
                                                    iconClasses = "w-3 h-6 mr-2",
                                                    onClick = () => {
                                                    }
                                                }) => {
    return (
        <button
            className={`${defaultClasses} ${classes}`}
            onClick={onClick}
            disabled={disabled}
        >
            <div className="flex">
                {
                    icon &&
                    <img
                        src={icon}
                        className={iconClasses}
                        alt={"icon"}
                    />
                }
                <div className="flex justify-center items-center flex-grow">
                    {content}
                </div>
            </div>
        </button>
    )
}


export const BasicButton = ({
    onClick=()=> {},
    content="",
    classes=""
                            })=> {
    return (
        <PeerButton
            classes={`${classes} bg-primary text-white text-md md:text-xl lg:text-3xl`}
            content={content}
            onClick={onClick}
        />
    )
}

export const PrimaryButton = ({
    onClick=()=> {},
    content="",
    disabled = false,
    classes=""
                            })=> {
    return (
        <PeerButton
            classes={`${disabled ? 'bg-gray-400' : 'bg-primary'} text-white text-xs md:text-sm lg:text-lg font-semibold ${classes}`}
            content={content}
            onClick={onClick}
            disabled={disabled}
        />
    )
}

export const SecondaryButton = ({
    onClick=()=> {},
    content="",
    classes=""
                            })=> {
    return (
        <PeerButton
            defaultClasses="px-6 py-1.5 md:px-9 md:py-2.5 lg:px-12 lg:py-3.5"
            classes={`${classes} text-xs md:text-sm lg:text-lg font-semibold border-2 border-primary bg-white text-primary`}
            content={content}
            onClick={onClick}
        />
    )
}

export const ThirdButton = ({
    onClick=()=> {},
    content="",
    classes=""
                            })=> {
    return (
        <PeerButton
            classes={`${classes} text-xs md:text-sm lg:text-lg border-0 text-primary underline`}
            content={content}
            onClick={onClick}
        />
    )
}

export const CarteButton = ({
    onClick=()=> {},
    content="",
    classes=""
                            })=> {
    return (
        <PeerButton
            defaultClasses="px-10 py-2 md:px-15 md:py-3.5 lg:px-24 lg:py-5"
            classes={`${classes} text-xs text-white bg-primary`}
            content={content}
            onClick={onClick}
        />
    )
}

export const TableButton: FC<PeerInterface> = ({
    onClick=()=> {},
    content="",
    icon=undefined,
    classes=""
                            })=> {
    return (
        <PeerButton
            defaultClasses="px-3 py-1 lg:px-5 lg:py-2"
            classes={`${classes} text-xs text-white rounded bg-primary hover:bg-opacity-80`}
            content={content}
            icon={icon}
            onClick={onClick}
        />
    )
}

export const TableButtonWithIcon: FC<PeerInterface> = ({
    onClick=()=> {},
    content="",
    icon=undefined,
    classes=""
                            })=> {
    return (
        <PeerButton
            defaultClasses="px-2 py-0.5 lg:px-4 lg:py-1"
            classes={`${classes} text-xs text-white rounded bg-primary`}
            iconClasses="w-2 h-4 lg:w-3 lg:h-6 mr-2"
            content={content}
            icon={icon}
            onClick={onClick}
        />
    )
}

export const FilterButtonWithIcon: FC<PeerInterface> = ({
    onClick=()=> {},
    content="",
    classes=""
                            })=> {
    return (
        <PeerButton
            defaultClasses="px-4 py-2"
            classes={`${classes} text-xs text-secondary bg-white bg-white bg-primary text-xs rounded`}
            content={content}
            iconClasses={"w-4 mr-2"}
            icon={filterIcon}
            onClick={onClick}
        />
    )
}

export const UnRoundedDwnButton = ({
    onClick=()=> {},
    icon=greyDownload,
    classes="",
    content=""
                            })=> {
    return (
        <PeerButton
            defaultClasses="px-2 py-0.25 lg:px-4 lg:py-0.5"
            classes={`${classes} text-xs text-primary bg-white border-primary border-2 bg-primary`}
            iconClasses="w-2 h-4 lg:w-3 lg:h-6 mr-2"
            icon={icon}
            onClick={onClick}
            content={content}
        />
    )
}

export const UnRoundedDwnButton2 = ({
    onClick=()=> {},
    icon=greyDownload,
    classes="",
    content=""
                            })=> {
    return (
        <PeerButton
            defaultClasses="px-2 py-0.25 lg:px-4 lg:py-0.5"
            classes={`${classes} text-xs border-white border-2 border-white text-[#000] bg-[#fff] rounded`}
            iconClasses="w-2 h-4 lg:w-3 lg:h-6 mr-2"
            icon={icon}
            onClick={onClick}
            content={content}
        />
    )
}

export const RequestButton = ({
    hollow=false,
    onClick=()=> {},
    icon=undefined,
    classes="",
    content=""
                            })=> {
    return (
        <PeerButton
            defaultClasses={`px-3 ${hollow ? 'py-0.5' : 'py-1'} lg:px-5 ${hollow ? 'lg:py-1.5' : 'lg:py-2'}`}
            classes={`${classes} text-xs ${hollow ? 'text-primary bg-white border-primary border-2 bg-primary' : 'bg-primary text-white font-semibold'}`}
            icon={icon}
            onClick={onClick}
            content={content}
        />
    )
}

export const AddButton = ({
    hollow=false,
    onClick=()=> {},
    icon=plus,
    classes="",
    content=""
                            })=> {
    return (
        <PeerButton
            defaultClasses={`px-3 ${hollow ? 'py-0.5' : 'py-1'} lg:px-5 ${hollow ? 'lg:py-1.5' : 'lg:py-2'}`}
            classes={`text-xs ${hollow ? 'text-primary bg-white border-primary border-2 bg-primary' : 'bg-primary text-white font-semibold '} ${classes}`}
            icon={icon}
            iconClasses="w-4 h-4 mr-2"
            onClick={onClick}
            content={content}
        />
    )
}
