import React, { FC, useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone as faPhoneSquareAlt,
  faEnvelope,
  faGlobe as faLink,
} from "@fortawesome/free-solid-svg-icons";
import Call from "../../assets/homepage/call2.svg";
import Message from "../../assets/homepage/message2.png";
import Global from "../../assets/homepage/global.svg";

interface SectionH2hInterface {}

const SectionH2h: FC<SectionH2hInterface> = () => {
  const [animateImage, setAnimateImage] = useState(false);
  const sectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const sectionTop = sectionRef.current.getBoundingClientRect().top;
        const sectionBottom = sectionRef.current.getBoundingClientRect().bottom;
        const isInViewport =
          sectionTop < window.innerHeight / 2 && sectionBottom > 0;
        setAnimateImage(isInViewport);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className=" bg-[#fff]" id="h2h" ref={sectionRef}>
      <div className="mx-[2%] lg:mx-0 px-[24px] lg:px-[42px] pt-4 pb-4 lg:pb-16 sm:flex text-white">
        <div className=" w-full bg-[#FBF1EE] p-10 md:p-14 xl:p-20 rounded-[40px] flex flex-col-reverse lg:flex-row lg:justify-between items-center">
          <div className="w-full lg:w-[30%] flex justify-center lg:justify-start">
            <div
              style={{
                transform: animateImage
                  ? "translateX(0%)"
                  : "translateX(-100%)",
                transition: "transform 1.0s ease-in-out",
              }}
            >
              <img
                src={require("../../assets/homepage/capture2.jpg")}
                alt=""
                className="h-60 lg:h-fit"
              />
            </div>
          </div>
          <div className="w-full lg:w-[65%] lg:pl-16">
            <h1 className="text-[#2D323B] text-[40px] font-bold w-full">
              Home 2 Home
            </h1>
            <p className="pt-[8px] pb-[8.5px] text-[#2D323B]">
              Verbouwplannen? Pak het verstandig aan. Renoveren waar nodig
            </p>
            <div className="flex flex-col lg:flex-row lg:flex-wrap">
              <a href="tel:037716625" className="w-full lg:w-fit">
                <button
                  type="button"
                  className="mt-4 transition ease-in-out hover:-translate-y-1 hover:scale-110 bg-white w-full font-medium p-[18px] text-[#00AFAA] rounded-[5px] mr-2 text-[16px] flex items-center common-button-shadow "
                >
                  <span className="pr-[10px]">
                    <img src={Call} alt="" />
                  </span>
                  03 771 66 25
                </button>
              </a>
              <a
                href="mailto:info@h2hvastgoed.be"
                className="w-full lg:w-fit lg:px-4"
              >
                <button
                  type="button"
                  className="mt-4 transition ease-in-out hover:-translate-y-1 hover:scale-110 w-full bg-white font-medium p-[18px] text-[#00AFAA] rounded-[5px] mr-2 text-[16px] flex items-center common-button-shadow "
                >
                  <span className="pr-[10px]">
                    <img src={Message} alt="" className="h-5" />
                  </span>
                  info@h2hvastgoed.be
                </button>
              </a>
              <a
                href="https://www.h2hvastgoed.be/renoveren-benoveren/"
                target="_blank"
                className="w-full lg:w-fit"
              >
                <button
                  type="button"
                  className="mt-4 transition ease-in-out hover:-translate-y-1 hover:scale-110 w-full bg-white font-medium p-[18px] text-[#00AFAA] rounded-[5px] mr-2 text-[16px] flex items-center common-button-shadow "
                >
                  <span className="pr-[10px]">
                    <img src={Global} alt="" />
                  </span>
                  info@h2hvastgoed.be
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionH2h;
