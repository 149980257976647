import React, { useState, useEffect } from "react";
import TitleBar from "../titleBar";
import { useNavigate } from "react-router-dom";
import { getLocalStateValue } from "../../../utils/helpers";
import Box from "../box";
import InfoModal from "../infoModal";

type HouseAgeProps = {
    globals?: {
        colors: {
            default: ""
        }
    },
    pageData?: {
        back_text: string,
        title: string,
        description: string,
        next_url: string,
        prev_url: string
    }
    updateAnswer?: (title: string, answer: string, url?: string) => void,
    storeAnswer?: (data: any, key: string) => void,
    modalInformation?: any
}

const HouseAge = ({ globals, pageData, updateAnswer = () => {}, storeAnswer = () => {}, modalInformation }: HouseAgeProps) => {
    const [ boxHover, setBoxHover ] = useState(0);
    const [ activeBox, setActiveBox ] = useState("");
    const [ modalState, setModalState ] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const localState = getLocalStateValue("2");
        if (localState) {
            setActiveBox(localState.answerNo);
        }
    }, [])

    const goBack = () => {
        navigate(pageData?.prev_url || "")
    }

    const onClick = (answer?: string, answerIndex?: string) => {
        const question = "bouwjaar";
        updateAnswer(question || "", answer || "", pageData?.next_url);
        storeAnswer({"answerNo": answerIndex, "bouwjaar": answer, image: ""}, "2");
    }

    const openInformationModal = () => {
        setModalState(true);
    }

    return(
        <div className = "w-full flex justify-center box-border">
            <div className = "container mx-auto flex-col justify-center box-border px-[16px] md:px-16 lg:px-32">
                <TitleBar back_text = {pageData?.back_text} infoColor = {globals?.colors?.default} title = {pageData?.title} description = {pageData?.description} goBack = {goBack} openInfoModal={openInformationModal} />
                <div className = "w-full flex flex-col mt-[10px] mb-[100px]">
                    <div className = "w-full flex flex-col md:flex-row border-b-[1px] border-b-[#F0F5F5]">
                        <Box flex={1} hasRightBorder text = "Voor 1900" hoverValue = {1} hoverState = {boxHover} onHoverEvent = {setBoxHover} color = {globals?.colors?.default} onRoute = {onClick} answerIndex = "0" isActive = {activeBox === "0" ? true : false} />
                        <Box flex={1} hasRightBorder text = "1901 - 1945" hoverValue = {2} hoverState = {boxHover} onHoverEvent = {setBoxHover} color = {globals?.colors?.default} onRoute = {onClick} answerIndex = "1" isActive = {activeBox === "1" ? true : false} />
                        <Box flex={1} text = "1946 - 1970" hoverValue = {3} hoverState = {boxHover} onHoverEvent = {setBoxHover} color = {globals?.colors?.default} onRoute = {onClick} answerIndex = "2" isActive = {activeBox === "2" ? true : false} />
                        <Box flex={1} hasRightBorder text = "1971 - 2000" hoverValue = {4} hoverState = {boxHover} onHoverEvent = {setBoxHover} color = {globals?.colors?.default} onRoute = {onClick} answerIndex = "3" isActive = {activeBox === "3" ? true : false} />
                        <Box flex={1} hasRightBorder text = "Na 2000" hoverValue = {5} hoverState = {boxHover} onHoverEvent = {setBoxHover} color = {globals?.colors?.default} onRoute = {onClick} answerIndex = "4" isActive = {activeBox === "4" ? true : false} />
                        <Box flex={1} text = "Ik weet het niet" hoverValue = {6} hoverState = {boxHover} onHoverEvent = {setBoxHover} color = {globals?.colors?.default} onRoute = {onClick} answerIndex = "5" isActive = {activeBox === "5" ? true : false} />
                    </div>
                    <div className = "w-full flex flex-col md:flex-row">
                        
                    </div>
                </div>
            </div>
            <InfoModal modalInformation = {modalInformation} isModalOpen = {modalState} closeModal = {() => setModalState(false)} />
        </div>
    )
}

export default HouseAge;