export const pageTitles = {
    home: 'Peer',
    questionnaire1: 'Type woning | Peer',
    questionnaire2: 'Bouwjaar | Peer',
    questionnaire3: 'Oppervlakte | Peer',
    questionnaire4: 'Bewoner | Peer',
    questionnaire5: 'Bewoners | Peer',
    questionnaire6: 'Verwarming | Peer',
    questionnaire8: 'Verwarmingselementen | Peer',
    questionnaire9: 'Verwarming leefruimtes | Peer',
    questionnaire10: 'Verwarming slaapkamers | Peer',
    questionnaire11: 'Warm water | Peer',
    questionnaire12: 'Hoofddak | Peer',
    questionnaire13: 'Bijdak | Peer',
    questionnaire14: 'Verwarmde ruimtes | Peer',
    questionnaire15: 'Ramen leefruimte | Peer',
    questionnaire16: 'Ramen slaapkamers | Peer',
    questionnaire17: 'Voorgevel | Peer',
    questionnaire18: 'Achtergevel | Peer',
    questionnaire19: 'Zijgevels | Peer',
    questionnaire20: 'Vloerisolatie | Peer',
    questionnaire21: 'Ventilatie | Peer',
    questionnaire22: 'Zonnepanelen | Peer',
    questionnaire23: 'Aantal zonnepanelen | Peer',
    questionnaire24: 'Terugdraaiende teller | Peer',
    questionnaire25: 'Digitale meter | Peer',
    questionnaire26: 'Energieverbruik en -productie | Peer',
    questionnaire27: 'Groendak | Peer',
    questionnaire28: 'Voor- of achtertuin | Peer',
    questionnaire29: 'Regenwaterput | Peer',
    questionnaire30: 'Verharding | Peer',
    questionnaire31: 'Klimaat | Peer',
    result: 'Resultaat | Peer',
  }