import React, { createContext, useReducer } from "react";
import AppReducer from "../reducer/appReducer";
// language file
import dutch from "../../languages/nl";

// Initial state
const initialState: any = {
  // Session data
  contractors: [],
  session: {},
  redirection: false,
  // language (default French)
  language: dutch,
  simulationInitDone: false,
  simulationId: null,
  colors: {
    light: '#67e8f9',
    default: '#00AFAA',
    dark: '#1D585A',
    accent:"#F0F5F5"
  },
  offerAllStatus: null,
  homeOwnerList: null,
  allContractorsList: null,
  // for filter dropdowns
  filtersData: {
    services: null,
    address: null,
    contractor: null,
    houseOwner: null,
  },
};

// Create context
export const GlobalContext = createContext(initialState);

// Provider component
export const GlobalProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
