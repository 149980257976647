import React, {FC, useEffect, useContext, useState} from "react";
import {GlobalContext} from "../context/globalContext/globalContext";
import {Routes, Route, useNavigate, Navigate,} from "react-router-dom";
import {Sessions} from "../utils/session";
// pages
import Results from "../containers/results";
import NotFound from "../components/404";
import {useCookies} from 'react-cookie'
import ApartmentPage from "../components/ApartmentPage";
import Form from "../containers/form";
import PrivacyPolicy from "../containers/privacyPolicy";

import Homepage from "../containers/homepage";

type CookieTypes = {
    userId: string,
    sessionData: {
        id: string,
        start_session: string,
        latest_update: string,
        email: string | null,
        address: string | null,
        news_letter_subscription: boolean | null,
        current_consumption: null,
        future_consumption: null,
        epc_score: null,
        createdAt: string | null,
        updatedAt: string | null,
        deletedAt: string | null
    }
}

interface RoutesInterface {
}

/**
 * routes page to define all pages routes here
 */
const PagesRoutes: FC<RoutesInterface> = () => {
    const navigate = useNavigate();
    const auth = JSON.parse(localStorage.getItem("auth") || "{}");
    const [cookies, setCookies, removeCookie] = useCookies();
    const [ cookieState, setCookieState ] = useState<CookieTypes>({ userId: '', sessionData: { id: '', start_session: '', latest_update: '', email: null, address: null, news_letter_subscription: null, current_consumption: null, future_consumption: null, epc_score: null, createdAt: null, updatedAt: null, deletedAt: null } });
    // global state
    const {dispatch, state}: any = useContext(GlobalContext);
    //useEffect to create new visited user Id which requires at the end for form submission
    useEffect(() => {
        Sessions(cookieState, setCookieState, dispatch);
    }, [dispatch]);
    const arrayNot = ["/vraag/asbest", "/vraag/vocht", "/vraag/groendak", "/vraag/voor-ofachtertuin", "/vraag/regenwaterput", "/vraag/verharding", "/vraag/klimaat"]

    useEffect(() => {
        console.log("GlobalContext state update", state)
        const currentPath = window.location.pathname;
        console.log(currentPath);
        if (state.redirection) {
            console.log("Go to form", state.session.id)
            // navigate("/v/vraag/1/typewoning?id="+state.session.id)
            window.location.replace("/vraag/typewoning");

        }
    }, [state]);

    return (
        <Routes>
                    <>
                        <Route path="/" element={<Homepage />}/>
                        <Route path="/vraag/:path" element={<Form globals = {state}  />} />
                        <Route path="/results" element={<Results globals={state} cookies={cookies}/>}/>
                        <Route path="/404" element={<NotFound globals={state}/>}/>
                        <Route path="/homepage" element={<Homepage />}/>
                        <Route path="/apartment" element={<ApartmentPage globals={state}/>}/>
                        <Route path="/privacy-policy/:hash" element={<PrivacyPolicy/>}/>
                        <Route path='*' element={<NotFound globals={state}/>}/>
                    </>
            {/* <Route path="/services/pdf" element={<ServicesOfferPdf />}/> */}
        </Routes>
    );
};
export default PagesRoutes;
