import Routes from "./routes";
import { GlobalProvider } from "./context/globalContext/globalContext";

// Google Analytics tracking code

// import ReactGA from "react-ga";
// ReactGA.initialize("");
// ReactGA.pageview(window.location.pathname + window.location.search);


const App = () => {
  return (
    <GlobalProvider>
      <Routes />
    </GlobalProvider>
  );
};

export default App;
