const Banner = () => {
  return (
    <div className="pt-10 lg:pt-[113px] mx-[4%] lg:mx-[8%]">
      <div className=" py-10">
        <h1 className="lg:text-[40px] text-[30px] font-semibold text-[#2D323B] text-left">
          Dit zijn de verschillende stappen
        </h1>
      </div>
      <div className="flex lg:flex-row flex-col justify-between lg:pb-20 lg:px-20 ">
        <div className="lg:w-[60%] w-full flex flex-col ">
          <div>
            <div className="flex">
              <div className="bg-[#02AFAA] w-[30px] mt-[5px] h-[30px] rounded-full"></div>
              <h2 className="pt-2 md:pt-0 text-[18px] md:text-[24px] text-[#2D323B] font-semibold ml-6 md:ml-12 ">
                1. Telefonisch contact
              </h2>
            </div>
            <div className="border-l-[8px] border-[#02AFAA] ml-[11px] mt-[-1px]">
              <div className="ml-10 md:ml-16">
                <p className="py-4 text-[16px] text-[#2D323B]">
                  De renovatiebegeleider van Home2Home, in opdracht van stad Peer, 
                  belt jou op om samen de verschillende stappen van het proces te doorlopen en 
                  een huisbezoek in te plannen.
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="flex">
            <div className="bg-[#02AFAA] w-[30px] h-[30px] rounded-full shadow ring-8 ring-[#02AFAA38]"></div>
              <h2 className="text-[18px] md:text-[24px] text-[#2D323B] font-semibold ml-6 md:ml-12 ">
                2. Huisbezoek
              </h2>
            </div>
            <div className="flex">
              <div className="px-1 ml-[11px] mt-[-8px] bg-gradient-to-t from-[#BFE6E4] to-[#02AFAA] z-24 "></div>
              <div className="ml-10 md:ml-16">
                <p className="py-4 text-[16px] text-[#2D323B]">
                  De renovatiebegeleider van Home2Home komt langs bij jouw thuis om alle technische aspecten van 
                  het huis te bekijken. Door een open gesprek aan te gaan weet de renovatiebegeleider welke werken 
                  jij zou willen uitvoeren.
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="flex">
              <div className="bg-[#BFE6E4] w-[30px] h-[30px] rounded-full"></div>
              <h2 className="text-[18px] md:text-[24px] text-[#2D323B] font-semibold ml-6 md:ml-12 ">
                3. Renovatieadvies
              </h2>
            </div>
            <div className="border-l-[8px] border-[#BFE6E4] ml-[11px] mt-[-8px] ">
              <div className="ml-10 md:ml-16">
                <p className="py-4 text-[16px] text-[#2D323B]">
                  Op basis van de rondgang in huis, maakt de renovatiebegeleider een advies op: welke werken zijn nodig, 
                  welke werken zijn prioriteit,…{" "}
                </p>
                <p className="py-4 text-[16px] text-[#2D323B]">
                  Daarbij maken jullie samen een eerste inschatting van de kosten en overlopen jullie de mogelijke premies 
                  die van toepassing zijn.{" "}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="flex">
              <div className="bg-[#BFE6E4] w-[30px] h-[30px] rounded-full"></div>
              <h2 className="text-[18px] md:text-[24px] text-[#2D323B] font-semibold ml-6 md:ml-12 ">
                4. Offertes
              </h2>
            </div>
            <div className="border-l-[8px] border-[#BFE6E4] ml-[11px] mt-[-8px]">
              <div className="ml-10 md:ml-16">
                <p className="py-4 text-[16px] text-[#2D323B]">
                  De renovatiebegeleider vraagt samen met jou offertes op maat op voor de werken die 
                  jij graag wil uitvoeren aan je woning.
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="flex">
              <div className="bg-[#BFE6E4] w-[30px] h-[30px] rounded-full"></div>
              <h2 className="text-[18px] md:text-[24px] text-[#2D323B] font-semibold ml-6 md:ml-12 ">
                5. Uitvoering werken
              </h2>
            </div>
            <div className="border-l-[8px] border-[#BFE6E4] ml-[11px] mt-[-8px]">
              <div className="ml-10 md:ml-16">
                <p className="py-4 text-[16px] text-[#2D323B]">
                  Op jouw vraag plant en controleert de renovatiebegeleider de werken die worden uitgevoerd.
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="flex">
              <div className="bg-[#BFE6E4] w-[30px] h-[30px] rounded-full"></div>
              <h2 className="text-[18px] md:text-[24px] text-[#2D323B] font-semibold ml-6 md:ml-12 ">
                6. Aanvraag premies
              </h2>
            </div>
            <div className="ml-[19px] ">
              <div className="ml-10 md:ml-16">
                <p className="py-4 text-[16px] text-[#2D323B]">
                  Wanneer alle werken uitgevoerd zijn, vraagt de renovatiebegeleider de nodige premies aan.
                </p>
                {/* <p className="py-4 font-semibold text-[16px] text-[#2D323B]">
                  Wilt u meer informatie over het process, neem dan contact met
                  ons op
                </p> */}
              </div>
            </div>
          </div>
          <div className="lg:mx-6 flex lg:justify-start justify-center pt-[30px]">
            <a
              href='#h2h'
              className="px-10 py-6 bg-[#02AFAA] text-[#fff] rounded-full font-semibold transition ease-in-out hover:-translate-y-1 hover:scale-110"
            >
              Contacteer ons
            </a>
          </div>
        </div>
        <div className="lg:w-[30%] w-full flex lg:flex-col flex-row lg:justify-end justify-center lg:mb-56 lg:py-0 py-10 ">
          <img alt="ad-img" src={require("../../assets/homepage/capture.jpg")} />
        </div>
      </div>
    </div>
  );
};
export default Banner;
