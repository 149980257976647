import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import TitleBar from "../titleBar";
import SubmitBox from "../submitBox";
import InputArea from "../inputArea";
import { getLocalStateValue } from "../../../utils/helpers";
import InfoModal from "../infoModal";

type HouseSolarProps = {
    globals?: {
        colors: {
            default: ""
        }
    },
    pageData?: {
        back_text: string,
        title: string,
        description: string,
        next_url: string,
        prev_url: string
    },
    updateAnswer?: (title: string, answer: string, url?: string) => void,
    storeAnswer?: (data: any, key: string) => void,
    modalInformation?: any
}

const HouseSolar = ({ globals, pageData, updateAnswer = () => {}, storeAnswer = () => {}, modalInformation }: HouseSolarProps ) => {
    const [ solarValue, setSolarValue ] = useState<string>("");
    const [ modalState, setModalState ] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const localState = getLocalStateValue("23");
        if (localState) {
            setSolarValue(localState.aantal_zonnepanelen);
        }
    }, [])

    const goBack = () => {
        navigate(pageData?.prev_url || "")
    }

    const onClick = () => {
        const question = "aantal_zonnepanelen";
        updateAnswer(question || "", solarValue || "", pageData?.next_url);
        storeAnswer({"answerNo": "0", "aantal_zonnepanelen": solarValue, image: ""}, "23")
    }

    const openInformationModal = () => {
        setModalState(true);
    }

    return(
        <div className = "w-full flex justify-center box-border">
            <div className = "container mx-auto flex-col justify-center box-border px-[16px] md:px-16 lg:px-32">
                <TitleBar back_text = {pageData?.back_text} infoColor = {globals?.colors?.default} title = {pageData?.title} description = {pageData?.description} goBack = {goBack} openInfoModal={openInformationModal} />
                <div className = "w-full mt-[18px]" />
                <SubmitBox onSubmit = {onClick} isButtonActive = {solarValue?.length > 0}>
                    <InputArea description = "Aantal zonnepanelen" marginLeft = {window.screen.width < 1000 ? "0px" : "138px"} value = {solarValue} setValue = {setSolarValue} enablePlaceholder = {false} />
                </SubmitBox>
                <div className = "w-full mt-[100px]" />
            </div>
            <InfoModal modalInformation = {modalInformation} isModalOpen = {modalState} closeModal = {() => setModalState(false)} />
        </div>
    )
}

export default HouseSolar;