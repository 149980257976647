import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TitleBar from "../titleBar";
import Box from "../box";
import { getLocalStateValue } from "../../../utils/helpers";
import InfoModal from "../infoModal";

// image imports
import BonfireOne from "../../../assets/bonfire_types/bonfire_one.png";
import BonfireTwo from "../../../assets/bonfire_types/bonfire_two.png";
import BonfireThree from "../../../assets/bonfire_types/bonfire_three.png";

type EananderebronProps = {
    globals?: {
        colors: {
            default: ""
        }
    },
    pageData?: {
        back_text: string,
        title: string,
        description: string,
        next_url: string,
        prev_url: string
    },
    updateAnswer?: (title: string, answer: string, url?: string) => void,
    storeAnswer?: (data: any, key: string) => void,
    modalInformation?: any
}

const Eananderebron = ({ globals, pageData, updateAnswer = () => {}, storeAnswer = () => {}, modalInformation }: EananderebronProps) => {
    const [ hoverState, setHoverState ] = useState(0);
    const [ activeBox, setActiveBox ] = useState("");
    const [ modalState, setModalState ] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const localState = getLocalStateValue("7");
        if (localState) {
            setActiveBox(localState.answerNo);
        }
    }, [])

    const goBack = () => {
        navigate(pageData?.prev_url || "")
    }

    const getImage = (answerIndex?: string) => {
        switch(answerIndex) {
            case "0":
                return BonfireOne;
            case "1":
                return BonfireTwo;
            case "2":
                return BonfireThree;
        }
    }

    const onClick = (answer?: string, answerIndex?: string) => {
        const question = "eananderebron";
        let nextRoute = pageData?.next_url
        if(answerIndex === "15" || answerIndex === "16") {
            nextRoute = "/vraag/verwarmingleefruimtes";
        }
        updateAnswer(question || "", answer || "", nextRoute);
        storeAnswer({"answerNo": answerIndex, "eananderebron": answer, image: getImage(answerIndex)}, "7")
    }

    const openInformationModal = () => {
        setModalState(true);
    }

    return(
        <div className = "w-full flex justify-center box-border">
            <div className = "container mx-auto flex-col justify-center box-border px-[16px] md:px-16 lg:px-32">
                <TitleBar back_text = {pageData?.back_text} infoColor = {globals?.colors?.default} title = {pageData?.title} description = {pageData?.description} goBack = {goBack} openInfoModal={openInformationModal} />
                <div className = "flex flex-col w-full mt-[10px] mb-[100px]">
                    <div className = "flex w-full flex-col md:flex-row">
                        <Box flex = {1} hasRightBorder text = "Warmtenet" hoverValue = {1} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {BonfireOne} answerIndex = "14" isActive = {activeBox === "0"} />
                        <Box flex = {1} hasRightBorder text = "Pellets" hoverValue = {2} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {BonfireTwo} answerIndex = "15" isActive = {activeBox === "1"} />
                        <Box flex = {1} hasRightBorder text = "Hout" hoverValue = {3} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {BonfireThree} answerIndex = "16" isActive = {activeBox === "2"} />
                    </div>
                </div>
            </div>
            <InfoModal modalInformation = {modalInformation} isModalOpen = {modalState} closeModal = {() => setModalState(false)} />
        </div>
    )
}

export default Eananderebron;