import React from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { MdChevronLeft } from "react-icons/md"

type TitleBarProps = {
    infoColor?: string,
    back_text?: string,
    description?: string,
    title?: string,
    goBack?: () => void;
    openInfoModal?: () => void;
}

const TitleBar = ({ infoColor, back_text, title, description, goBack, openInfoModal }: TitleBarProps) => {

    return(
        <div className = "flex flex-col md:flex-row w-full justify-center items-center relative mt-[25px]">
            <div className = "md:absolute md:left-0 md:top-0 flex w-full md:w-auto justify-start items-center cursor-pointer" onClick = {goBack}>
                <MdChevronLeft size = "2em" color = "black" />
                <p className = "font-semibold font-sans text-[21px] text-[black] ml-[10px] capitalize">{back_text}</p>
            </div>
            <div className = "flex flex-col items-center">
                <div className = "flex flex-row items-center">
                    <p className = "font-semibold font-sans text-[30px] text-[black] mr-[15px] md:mr-[25px] text-center">{title}</p>
                    {/* <div onClick = {openInfoModal}>
                        <BsFillInfoCircleFill size = "1.5em" color = {infoColor} className = "cursor-pointer" />
                    </div> */}
                </div>
                <p className = "font-medium font-sans text-[18px] text-[black] md:mr-[25px] text-center md:mt-[25px]" dangerouslySetInnerHTML={{
                    //@ts-ignore 
                __html: description}}></p>
            </div>
        </div>
    )
}

export default TitleBar;