import React from "react";

type TermsAndConditionsProps = {};

const TermsAndConditions = () => {
  return (
    <div className="pl-6">
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-3 font-semibold">
          Algemene opmerking: deze cookie notice is gebaseerd op de huidige e-Privacy Directive en de GDPR.
          Indien en wanneer de nieuwe e-Privacy Verordening is aangenomen, zal dit document mogelijks
          geüpdate moeten worden.
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 font-semibold py-3">
        1. Over deze Policy
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
        1.1 Deze Cookie Policy ("Policy") legt uit hoe Agifly bv en haar groepsentiteiten (gezamenlijk “Agifly
          bv”, "wij", "ons" en "onze") gebruik maken van cookies en gelijkaardige technologieën om u te
          herkennen wanneer u onze website bezoekt op{" "}
        <a className="text-primary" href="https://energiecoach.planeetpeer.be/" target="_blank">
            energiecoach.planeetpeer.be
        </a>{" "}
          Het legt uit wat deze
          technologieën zijn en waarom we ze gebruiken, alsook uw rechten om ons gebruik ervan te beheren.
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-2">
        1.2 Neem alstublieft de tijd om deze Policy grondig te lezen. Indien u vragen of opmerkingen zou
          hebben, gelieve onze ISM office te contacteren via Agifly bv.alain@agifly.be.
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 font-semibold py-3">
        2. Wat zijn cookies?
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
          2.1 Cookies zijn kleine tekstbestanden die op uw computer of mobiel apparaat worden geplaatst
          wanneer u een website bezoekt. Cookies worden veelvuldig gebruikt door eigenaars van websites
          om hun websites te laten werken, of om deze efficiënter te laten werken, alsook om informatie te
          rapporteren.
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-2">
          2.2 Cookies hebben veel verschillende functies, zoals u toelaten om efficiënt te navigeren tussen
          pagina's, het onthouden van uw voorkeuren, en in het algemeen, om de gebruikerservaring te
          verbeteren. Cookies kunnen eveneens helpen om ervoor te zorgen dat de advertenties die u online
          ziet relevanter zijn voor u en uw interesses.
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
          2.3 Cookies die geplaatst worden door de eigenaar van de website (in dit geval, Agifly bv) worden
          "eerste partijcookies" genoemd. Cookies die geplaatst worden door anderen dan de eigenaar van de
          website worden "derde partijcookies" genoemd. Derde partijcookies laten toe om eigenschappen of
          functionaliteiten van derde partijen beschikbaar te stellen op of via de website (bv. advertenties,
          interactieve content en analyses). De partijen die deze derde partijcookies plaatsen kunnen uw
          computer herkennen zowel wanneer u de website in kwestie bezoekt als wanneer u bepaalde andere
          websites bezoekt.
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 font-semibold py-3">
        3. Hoe lang worden cookies bewaard?
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
          Cookies kunnen op uw computer of mobiel apparaat bewaard blijven gedurende verschillende
          tijdspannes. Sommige cookies zijn "sessiecookies", wat betekent dat ze enkel bestaan wanneer uw
          website browser openstaat en automatisch verwijderd worden eens u uw browser sluit of de
          applicatie verlaat. Andere cookies zijn "permanente cookies", wat betekent dat ze blijven bestaan
          wanneer uw browser of applicatie gesloten is en door websites of applicaties gebruikt kunnen
          worden om uw computer te herkennen wanneer u later opnieuw uw browser of applicatie opent. De
          duur van de cookies die wij op onze Website gebruiken, wordt hieronder in de tabel gedetailleerd
          beschreven.
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 font-semibold py-3">
        4. Wat met andere tracking technologieën, zoals web beacons?
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
        4.1 Cookies zijn niet de enige manier om bezoekers van een website te herkennen of te volgen. Van
          tijd tot tijd kunnen we andere, gelijkaardige technologieën gebruiken, zoals web beacons (soms
          "tracking pixels" of "clear gifs" genoemd). Dit zijn kleine grafische bestanden die een unieke
          identificator bevatten die ons toelaten om te herkennen wanneer iemand onze Website heeft bezocht
          [of een e-mail heeft geopend die we hen hebben gestuurd]. Dit laat ons toe om, bijvoorbeeld, [het
          internetverkeer van gebruikers van de ene pagina op onze Website naar een andere te volgen,
          cookies aan te leveren of ermee te communiceren, te begrijpen of u naar onze Website bent
          gekomen vanop een online advertentie die getoond wordt op een derde partij website, de prestatie
          van onze Website te verbeteren, en om het succes te meten van e-mail marketing campagnes]. In
          veel gevallen zullen deze technologieën afhankelijk zijn van cookies om juist te kunnen functioneren,
          en dus zal het weigeren van cookies een effect hebben op hun werking.
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 font-semibold py-3">
        5. Wat met gerichte reclame?
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
        5.1 Derde partijen kunnen cookies bedienen op uw computer of mobiel apparaat om reclame te
          tonen via onze Website. Deze bedrijven kunnen informatie gebruiken over uw bezoeken aan deze
          Website of andere websites om relevante advertenties over goederen en diensten waarin u mogelijks
          geïnteresseerd bent te weergeven. Ze kunnen ook gebruik maken van technologie die gebruikt wordt
          om de doeltreffendheid van advertenties te meten. Dit kan worden bereikt door gebruik te maken van
          cookies of web beacons om informatie te verzamelen over uw bezoeken aan deze Website en
          andere websites om relevante advertenties te tonen over goederen en diensten waarin u mogelijks
          geïnteresseerd bent. De informatie die door dit proces wordt verzameld laat ons of hen niet toe om
          uw naam, contactgegevens of andere persoonlijke identificeerbare gegevens te identificeren, tenzij u
          ervoor kiest om deze te verstrekken.
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 font-semibold py-3">
        6. Hoe kan ik cookies controleren?
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
        6.1 U beschikt over het recht om te beslissen of u cookies aanvaardt of weigert. U kan uw cookie
          voorkeuren uitoefenen door te klikken op de relevante opt-out links die opgenomen zijn in de cookie
          tabel hierboven.
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-2">
        6.2 U kunt uw web browser instellingen instellen of wijzigen om cookies te aanvaarden of te
          weigeren. Indien u ervoor kiest om cookies te weigeren, kan u nog steeds onze Website gebruiken al
          kan uw toegang tot bepaalde functionaliteiten en delen van onze Website beperkt worden.
          Aangezien de middelen waarmee u cookies kan weigeren via uw web browser instellingen
          verschillen van browser tot browser, dient u voor meer informatie het hulp menu van uw browser te
          raadplegen.
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
        6.3  Daarenboven bieden de meeste advertentienetwerken u een mogelijkheid om u af te melden voor
          gerichte reclame. Indien u meer informatie wenst te verkrijgen, gelieve {" "}
        <a
          className="text-primary underline break-all"
          href="http://www.aboutads.info/choices/"
          target="_blank"
        >
          http://www.aboutads.info/choices
        </a>{" "}
        of{" "}
        <a
          className="text-primary underline break-all"
          href="http://www.youronlinechoices.com"
          target="_blank"
        >
          http://www.youronlinechoices.com
        </a>{" "}
        te raadplegen.
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 font-semibold py-3">
        7. De cookies die in het verleden werden gestuurd
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
        7.1 Indien u een of meer cookies heeft uitgeschakeld, kunnen we nog steeds informatie gebruiken
          die verzameld werd door deze cookies voordat de cookie werd uitgeschakeld. We zullen echter
          ophouden met informatie te verzamelen via de opted-out cookie.
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 font-semibold py-3">
        8. Meer informatie over cookies
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
        8.1 Voor verdere informatie over cookies, met inbegrip van uitleg over hoe cookies worden geplaatst
          op uw toestel, of hoe u deze kan beheren of verwijderen, raadpleeg {" "}
        <a
          className="text-primary underline break-all"
          href="http://www.allaboutcookies.org"
          target="_blank"
        >
          http://www.allaboutcookies.org.
        </a>
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 font-semibold py-3">
        9. Wijzigingen aan deze Policy
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900">
        9.1 Deze Policy kan van tijd tot tijd gewijzigd worden om aangepast te worden aan juridische,
          technische of commerciële wijzigingen. Wanneer we onze Policy zouden wijzigen, zullen we de
          gepaste maatregelen nemen om u hierover te informeren, aangepast aan het belang van de
          wijzigingen die we doorvoeren. Indien en waar vereist door de toepasselijke wetgeving betreffende
          gegevensbescherming, zullen wij uw toestemming vragen voor materiële wijzigingen aan deze
          Policy.
      </p>
      <p className="md:text-xs xl:text-sm 2xl:text-base text-gray-900 py-2">
        9.2 U kunt zien wanneer deze Policy voor het laatst werd gewijzigd, door de "laatst gewijzigd op"
          datum te bekijken die bovenaan deze Policy wordt getoond.
      </p>
    </div>
  );
};
export default TermsAndConditions;
