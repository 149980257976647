import React, { FC, useState, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { ScreenSize } from "../../utils/windowDimension";
import { useLocation, useNavigate } from "react-router-dom";

import logoPeer from "../../assets/logo/peer.svg";
import houseHeader from "../../assets/picture/house-header.png";
import CallMeBack from "../../components/modals/callMeBack";
import EnergiescanAanvragen from "../modals/energiescanAanvragen";
import "../../styles/header.css";

// languages
import Dutch from "../../languages/nl";

interface HeaderInterface {}

const Header: FC<HeaderInterface> = () => {
  const [openCallMeBack, setOpenCallMeBack] = useState(false);
  const [openEnergiescanAanvragen, setOpenEnergiescanAanvragen] =
    useState(false);
  const navigate = useNavigate();
  // global state
  const { state, dispatch }: any = useContext(GlobalContext);
  // to Get ScreenSize
  const screenSize = ScreenSize();
  // toggle state to show and hide language option
  const [toggle, setToggle] = useState(false);
  const [openNavMenu, setOpenNavMenu] = useState(false);
  // state to show default selected language
  const [selectedLanguage, setSelectedLanguage] = useState(
    state?.language?.language.toUpperCase()
  );

  return (
    <>
      <header className="z-40">
        <nav className="bg-white w-full z-20 top-0 ">
          <div className="flex flex-wrap items-center h-full px-2 py-2 mx-[3%] lg:ml-[3%] lg:mr-[1.6%]">
            <div
              onClick={() => navigate("/")}
              className="items-center h-full log-hass cursor-pointer"
            >
              <img src={logoPeer} className="h-20 md:h-full" alt="Peer Logo" />
            </div>
            <div className="flex items-center" style={{ marginLeft: "auto" }}>
              <button
                onClick={() => setOpenNavMenu(!openNavMenu)}
                type="button"
                className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="mobile-menu-2"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className={`${openNavMenu ? "hidden" : ""} w-6 h-6`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <svg
                  className={`${openNavMenu ? "" : "hidden"} w-6 h-6`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div
              className="hidden justify-between items-center content-end md:flex w-auto mt-2"
              style={{ marginLeft: "auto" }}
            >
              <button
                type="button"
                className="transition ease-in-out hover:-translate-y-1 hover:scale-110 text-[#2D323B] bg-white underline focus:ring-4 focus:ring-peer-accent font-semibold px-3 py-3 mr-8 "
                onClick={() => {
                  setOpenCallMeBack(true);
                }}
              >
                Bel mij terug
              </button>
              <button
                type="button"
                className="transition ease-in-out hover:-translate-y-1 hover:scale-110 text-white focus:ring-4 focus:ring-peer-accent font-semibold bg-[#02AFAA] text-white rounded-[41px] px-[32px] py-4"
                onClick={() => {
                  setOpenEnergiescanAanvragen(true);
                }}
              >
                Energiecoach aanvragen
              </button>
            </div>
          </div>
          <div
            className={`z-[100] bg-white mt-0 absolute shadow w-full px-2 ${
              openNavMenu ? "" : "hidden "
            } md:hidden `}
          >
            <div className="container py-4 flex flex-wrap items-center justify-between mx-auto h-full px-5">
              <ul className="list-none w-full">
                <li className="my-2 w-full">
                  <button
                    type="button"
                    className="transition ease-in-out hover:-translate-y-1 hover:scale-110 w-full focus:ring-4 focus:ring-peer-accent font-semibold text-[#2D323B] outline-none rounded-[41px] px-[32px] py-4 underline"
                    onClick={() => {
                      setOpenCallMeBack(true);
                    }}
                  >
                    Bel mij terug
                  </button>
                  {/* <button type="button" className="text-gray-900 bg-white border-2 border-black hover:bg-black hover:text-white focus:ring-4 focus:ring-peer-accent font-semibold px-5 py-3 mr-8" onClick={() => {setOpenCallMeBack(true)}}>Bel mij terug</button> */}
                </li>
                <li className="my-2 w-full">
                  <button
                    type="button"
                    className="transition ease-in-out w-full hover:-translate-y-1 hover:scale-110 text-white focus:ring-4 focus:ring-peer-accent font-semibold bg-[#02AFAA] text-white rounded-[41px] px-[32px] py-4"
                    onClick={() => {
                      setOpenEnergiescanAanvragen(true);
                    }}
                  >
                    Energiecoach aanvragen
                  </button>
                  {/* <button type="button" className="text-white bg-peer border-2 border-peer hover:bg-peer-dark hover:border-peer-dark focus:ring-4 focus:ring-peer-accent font-semibold	px-5 py-3" onClick={() => {setOpenEnergiescanAanvragen(true)}}>Energiescan aanvragen</button> */}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <CallMeBack open={openCallMeBack} setOpen={setOpenCallMeBack} />
      <EnergiescanAanvragen
        open={openEnergiescanAanvragen}
        setOpen={setOpenEnergiescanAanvragen}
      />
    </>
  );
};

export default Header;
