import { useEffect, useState } from "react";

export const ScreenSize = () => {
  // state to save screen dimensions.
  const [windowDimenion, detectHW] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // handler to detect with and hight and to save it.
  const detectSize = () => {
    detectHW({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  // useEffect to get dimension and change if screen size change.
  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  return windowDimenion;
};
