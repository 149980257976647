import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import { getOverviewValues,getObjValuesSum,getLoan } from "../../utils/reultHelpers";
import Pieces from "../../assets/pieces.png";
type priceCalculationProps = {
    openSimulationmodal?: () => void
    pageData?: {
        back_text: string,
        title: string,
        next_url: string,
        prev_url: string
    },
    hasImprovement:boolean,
    setHasImprovement:any
}







const PriceCalculation = ({ pageData, openSimulationmodal = () => {},hasImprovement,setHasImprovement }: priceCalculationProps) => {
    const [co2Value, setCO2Value] = useState(0)
    const [minderEnergie, setMinderEnergieValue] = useState(0)
    const [gas, setGasValue] = useState(0)
    const [electricity, setElectricityValue] = useState(0)
    const [bespaar, setBespaarValue] = useState(0)
    const [loan, setLoan] = useState(0)
    const results:any =  localStorage.getItem('result')
    const values:any = JSON.parse(results);
    const minderEnergieZonnepanelen = values?.minderEnergie?.zonnepanelen || 0
    const invester = JSON.parse(localStorage.getItem('Invester')!)
    const bespar = JSON.parse(localStorage.getItem('Bespaar')!)
    // console.log(bespar,'bespar');
    const premies = JSON.parse(localStorage.getItem('Premies')!)
    const minder = JSON.parse(localStorage.getItem('MinderEnergie')!)
    const gasLocal = JSON.parse(localStorage.getItem('PagesEnergyGas')!)
    // console.log(gasLocal,'gasLocal');
    const electricLocal = JSON.parse(localStorage.getItem('PagesEnergyElec')!)
    const co2 = JSON.parse(localStorage.getItem('Co2')!)
    const investeerVal = getObjValuesSum(invester !== null && invester !== undefined ? invester : 0)
    const bespaarVal = getObjValuesSum(bespar !== null && bespar !== undefined ? bespar : 0)
    //   console.log(bespaarVal,'bespaarVal');
    const premiesVal = getObjValuesSum(premies !== null && premies !== undefined ? premies : 0)
    const minderEnergieVal = getObjValuesSum(minder !== null && minder !== undefined ? minder : 0)
    const gasVal = getObjValuesSum(gasLocal !== null && gasLocal !== undefined ? gasLocal : 0)
    const electricityVal = getObjValuesSum(electricLocal !== null && electricLocal !== undefined ? electricLocal : 0)
    const co2Val = getObjValuesSum(co2 !== null && co2 !== undefined ? co2 : 0)
    const localAnswers:any =  localStorage.getItem('localState')
    const localState = JSON.parse(localAnswers)
    const localImprovements = JSON.parse(localAnswers)
    const navigate = useNavigate()


    useEffect(() => {
        getOverviewValues(
            localState,
            localImprovements,
            setMinderEnergieValue,
            setCO2Value,
            setBespaarValue,
            setElectricityValue,
            setGasValue,
            Cookies.get("userId"),
        //   values,
        )
    }, [localImprovements,invester,bespar,premies,minder,gasLocal])


    useEffect(() => {
        const maximumbedragEnergielening = Number(localStorage.getItem('maximumbedragEnergielening'))

        const amount = Number(investeerVal) > maximumbedragEnergielening ? maximumbedragEnergielening : Number(investeerVal);

     
          (async () => {
            const loanAmount =await getLoan(amount)
            setLoan(loanAmount)
        })();

        
    }, [investeerVal])

    const removeLocalStorageItems = async (): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
        try {
            console.log("clear all")
            const auth = localStorage.getItem("auth") || "";
            localStorage.clear()
            localStorage.setItem("auth",auth)
            // localKeys.forEach((key) => {
            // localStorage.removeItem(key);
            // });
            resolve();
        } catch (error) {
            reject(error);
        }
        });
    };

    const removeCookies = async (): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
        try {
            // Object.keys(Cookies.get()).forEach((cookieName) => {
            //   Cookies.remove(cookieName, { path: '/' });
            // });coo
            Cookies.remove("sessionData", { path: '/' });
            Cookies.remove("userId", { path: '/' });
            
            resolve();
        } catch (error) {
            reject(error);
        }
        });
    };

    const herstart = ()=>{
        removeLocalStorageItems().then(() => {
            removeCookies().then(() => {
                navigate("/");
                // navigate(0);
                console.log("cookie and storage clean")
            })
        })
    }

    return (
//@ts-ignore
        <div className="flex flex-2 flex-col box-border px-[10px]">
            <div className="flex flex-col w-full bg-white box-border px-[15px] pt-[25px]">
                <div className="w-full flex flex-col-reverse lg:flex-row justify-between relative">
                    <p className="font-sans font-bold text-black text-[22px] mb-10 ">Overzicht van je besparing</p>
                    <img className= "h-[120px] object-contain lg:absolute right-[-15px] top-[-65px]" src={Pieces} alt="" />
                </div>
                <div className="w-full flex justify-between">
                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">Ik investeer</p>
                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">€ {Math.round(investeerVal? investeerVal : 0)}</p>
                </div>
                <div className="w-full flex justify-between">
                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">Ik betaal met de energielening *</p>
                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">€ {Number.isNaN(Math.round(loan)) ? 0 :Math.round(loan)} / maand</p>
                </div>
                <div className="w-full flex justify-between">
                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">Ik ontvang premies</p>
                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">€ {Math.round(premiesVal ? premiesVal : 0)}</p>
                </div>
                <div className="w-full flex justify-between">
                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">Ik verbruik minder elektriciteit</p>
                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">{Math.round(electricityVal ? electricityVal: 0) +
                      Math.round(minderEnergieZonnepanelen)}{' '} kWh/jaar </p>
                </div>
                <div className="w-full flex justify-between">
                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">Ik verbruik minder gas</p>
                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">{Math.round(gasVal ? gasVal : 0)} kWh/jaar</p>
                </div>
                <div className="w-full flex justify-between">
                    <p className="font-sans font-semibold text-black text-[16px] mb-5">Ik bespaar CO2</p>
                    <p className="font-sans font-semibold text-black text-[16px] mb-5">{Math.round(co2Val? co2Val :0)} kg</p>
                </div>
                <div className="w-full flex justify-between">
                    <p className="font-sans font-bold text-black text-[16px] mt-5">Ik bespaar</p>
                    <p className="font-sans font-bold text-black text-[16px] mt-5">€ {Math.round(bespaarVal) ? bespaarVal : 0} / jaar</p>
                </div>
                <div className="w-full flex justify-between">
                    <p className="font-sans font-regular text-[#39474E] text-[12px] mt-5">(*) Mijn VerbouwLening: Maximum €60.000, looptijd 20 jaar (maximaal 25), rentevoet 0%. Let op, geld lenen kost ook geld.</p>
                </div>
                {/* <div className="w-full flex justify-center my-[25px]">
                    {hasImprovement &&
                        <button type='button' className="h-[60px] w-[292px] flex items-center justify-center bg-white border-4 border-black transition ease-in-out hover:-translate-y-1 hover:scale-110 text-gray-900 bg-white border-2 border-black hover:bg-black hover:text-white focus:ring-4 focus:ring-peer-accent font-semibold px-5 py-3" onClick = {openSimulationmodal}>
                            <p className="font-sans font-bold text-[14px]">Offerte aanvragen</p>
                        </button>                  
                    }
                </div> */}
            </div>
            <div className="flex flex-col w-full bg-white box-border p-[15px] mt-[23px] justify-center">
                <div className="w-full flex justify-between items-center">
                    <p className="font-sans font-semibold text-black text-[16px] mb-2.5">Wil je de test opnieuw doen?</p>
                    <button type='button' className="w-[124px] flex items-center justify-center bg-[#398284] transition ease-in-out hover:-translate-y-1 hover:scale-110 text-white bg-peer border-2 border-peer focus:ring-4 focus:ring-peer-accent font-semibold px-5 py-3" onClick = {() =>{herstart()}}>
                        <p className="font-sans font-bold text-white text-[18px]">Herstart</p>
                    </button>
                </div>
            </div>
        </div>


    )
};
export default PriceCalculation;