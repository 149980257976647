import axios from "axios";
console.log("localStorage.getItemauth)",localStorage.getItem("auth"))
const authOrigin = localStorage.getItem("auth");
const auth = authOrigin && authOrigin?.length > 0? JSON.parse(localStorage.getItem("auth")!) : ""
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${auth?.token}`,
};
// GET API CAll
export const GetAPIService = (Url: any) => {
  return axios.get(process.env.REACT_APP_BACKEND_URL + Url,{headers});
};
// Post API CAll
export const PostAPIService = (Url: any, payload: any) => {
  return axios.post(process.env.REACT_APP_BACKEND_URL + Url, payload, {headers: {
      // 'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${auth?.token}`,
    }});
};
// Post Form API CAll
export const PostFormAPIService = (Url: any, payload: any) => {
  return axios.post(process.env.REACT_APP_BACKEND_URL + Url, payload, {headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${auth?.token}`,
  }});
};
// Put Form API CAll
export const PutFormAPIService = (Url: any, payload: any) => {
  return axios.put(process.env.REACT_APP_BACKEND_URL + Url, payload, {headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${auth?.token}`,
  }});
};
// Put API Call
export const PutAPIService = (Url: any, payload: any) => {
  return axios.put(process.env.REACT_APP_BACKEND_URL + Url, payload,{headers: {
      // 'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${auth?.token}`,
    }});
};
// Delete API CAll
export const DeleteAPIService = (Url: any, payload: any) => {
  return axios.delete(process.env.REACT_APP_BACKEND_URL + Url, {data:payload,headers});
};
