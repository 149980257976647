import React from "react";
import "./form.css";

type InputAreaProps = {
    title?: string,
    description?: string,
    marginLeft?: string,
    value?: string,
    setValue?: (value: string) => void,
    enablePlaceholder?: boolean
}

const InputArea = ({ title, description, marginLeft = "0px", value, setValue = () => {}, enablePlaceholder = true }: InputAreaProps ) => {
    const onChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    }

    return(
        <div className = "flex flex-col w-full mt-[15px] box-border md:px-0 px-[25px]" style = {{marginLeft}}>
            <p className = "font-sans font-semibold text-[22px] text-black">{title}</p>
            <p className = "font-sans font-regular text-[16px] text-black mt-[10px]">{description}</p>
            <div className = {`w-full max-w-[674px] h-[60px] border-[2px] border-black flex items-center box-border px-[17px] mt-[15px]`}>
                <input type = "number" className = "font-sans font-medium text-[14px] text-black w-full outline-none form" placeholder = {enablePlaceholder ? "0" : ""} value = {value} onChange = {onChangeEvent} />
            </div>
        </div>
    )
}

export default InputArea;