import React, { useState, useEffect } from "react";
import TitleBar from "../titleBar";
import { useNavigate } from "react-router-dom";
import Box from "../box";
import { getLocalStateValue } from "../../../utils/helpers";
import InfoModal from "../infoModal";

// image imports
import HouseOne from "../../../assets/house_types/woning_one.png";
import HouseTwo from "../../../assets/house_types/woning_two.png";
import HouseThree from "../../../assets/house_types/woning_three.png";
import HouseFour from "../../../assets/house_types/woning_four.png";

type HouseTypeProps = {
    globals?: {
        colors: {
            default: "#398284"
        }
    },
    pageData?: {
        back_text: string,
        title: string,
        description: string,
        next_url: string,
        prev_url: string
    },
    updateAnswer?: (title: string, answer: string, url?: string) => void,
    storeAnswer?: (data: any, key: string) => void,
    modalInformation?: any
}

const HouseType = ({ globals, pageData, updateAnswer = () => {}, storeAnswer = () => {}, modalInformation }: HouseTypeProps ) => {
    const [ boxHover, setBoxHover ] = useState(0);
    const [ activeBox, setActiveBox ] = useState("");
    const [ modalState, setModalState ] = useState(false);
        
    const navigate = useNavigate();

    useEffect(() => {
        const localState = getLocalStateValue("1");
        if (localState) {
            setActiveBox(localState.answerNo);
        }
    }, [])

    const goBack = () => {
        navigate(pageData?.prev_url || "")
    }

    const getImage = (answerIndex?: string) => {
        switch(answerIndex) {
            case "0":
                return HouseOne;
            case "1":
                return HouseTwo;
            case "2":
                return HouseThree;
            case "3":
                return HouseFour;
        }
    }

    const onClick = (answer?: string, answerIndex?: string) => {
        const question = "type_woning";
        if(answerIndex === "3") {
            updateAnswer(question || "", answer || "", "/apartment");
            storeAnswer({"answerNo": answerIndex, "type_woning": answer, image: getImage(answerIndex)}, "1");
        }
        else {
            updateAnswer(question || "", answer || "", pageData?.next_url);
            storeAnswer({"answerNo": answerIndex, "type_woning": answer, image: getImage(answerIndex)}, "1");
        }
    }

    const openInformationModal = () => {
        setModalState(true);
    }

    return(
        <div className = "w-full flex justify-center box-border">
            <div className = "container mx-auto flex-col justify-center box-border px-[16px] md:px-16 lg:px-32">
                <TitleBar back_text = {pageData?.back_text} infoColor = {globals?.colors?.default} title = {pageData?.title} description = {pageData?.description} goBack = {goBack} openInfoModal={openInformationModal} />
                <div className = "w-full flex flex-col mt-[10px] mb-[100px]">
                    <div className = "w-full flex md:h-auto sm:flex-row flex-col border-b-[1px] border-b-[#F0F5F5]">
                        <Box flex = {1} hasRightBorder text = "Open bebouwing" hoverValue = {1} hoverState = {boxHover} onHoverEvent = {setBoxHover} color = {globals?.colors?.default} onRoute = {onClick} image = {HouseOne} answerIndex = "0" isActive = {activeBox === "0" ? true : false} />
                        <Box flex = {1} text = "Halfopen bebouwing" hoverValue = {2} hoverState = {boxHover} onHoverEvent = {setBoxHover} color = {globals?.colors?.default} onRoute = {onClick} image = {HouseTwo} answerIndex = "1" isActive = {activeBox === "1" ? true : false} />
                        <Box flex = {1} hasRightBorder text = "Gesloten bebouwing" hoverValue = {3} hoverState = {boxHover} onHoverEvent = {setBoxHover} color = {globals?.colors?.default} onRoute = {onClick} image = {HouseThree} answerIndex = "2" isActive = {activeBox === "2" ? true : false} />
                        <Box flex = {1} text = "Appartement" hoverValue = {4} hoverState = {boxHover} onHoverEvent = {setBoxHover} color = {globals?.colors?.default} onRoute = {onClick} image = {HouseFour} answerIndex = "3" isActive = {activeBox === "3" ? true : false} />
                    </div>
                </div>
            </div>
            <InfoModal modalInformation = {modalInformation} isModalOpen = {modalState} closeModal = {() => setModalState(false)} />
        </div>
    )
}

export default HouseType;