
import { PostAPIService,GetAPIService, PutAPIService } from "../services";

//  handler to save all steps data
export const openSession = () => {
  return PostAPIService(`/session`,{});
};

export const getSession = (id : any) => {
  return GetAPIService(`/session/${id}`);
};

export const updateSession = (id : any,payload : any) => {
  return PutAPIService(`/session/${id}`,payload);
};
