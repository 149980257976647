import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TitleBar from "../titleBar";
import Box from "../box";
import { getLocalStateValue } from "../../../utils/helpers";
import InfoModal from "../infoModal";

// image imports 
import GasOne from "../../../assets/gas_types/gas_one.png";
import GasTwo from "../../../assets/gas_types/gas_two.png";
import GasThree from "../../../assets/gas_types/gas_three.png";
import GasFour from "../../../assets/gas_types/gas_four.png";

type StookolieProps = {
    globals?: {
        colors: {
            default: ""
        }
    },
    pageData?: {
        back_text: string,
        title: string,
        description: string,
        next_url: string,
        prev_url: string
    },
    updateAnswer?: (title: string, answer: string, url?: string) => void,
    storeAnswer?: (data: any, key: string) => void,
    modalInformation?: any
}

const Stookolie = ({ globals, pageData, updateAnswer = () => {}, storeAnswer = () => {}, modalInformation }: StookolieProps) => {
    const [ hoverState, setHoverState ] = useState(0);
    const [ activeBox, setActiveBox ] = useState("");
    const [ modalState, setModalState ] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const localState = getLocalStateValue("7");
        if (localState) {
            setActiveBox(localState.answerNo);
        }
    }, [])

    const goBack = () => {
        navigate(pageData?.prev_url || "")
    }

    const getImage = (answerIndex?: string) => {
        switch(answerIndex) {
            case "0":
                return GasOne;
            case "1":
                return GasTwo;
            case "2":
                return GasThree;
            case "3":
                return GasFour;
        }   
    }

    const onClick = (answer?: string, answerIndex?: string) => {
        const question = "stookolie";
        let nextRoute = pageData?.next_url
        if(answerIndex === "0") {
            nextRoute = "/vraag/verwarmingleefruimtes";
        }
        updateAnswer(question || "", answer || "", nextRoute);
        storeAnswer({"answerNo": answerIndex, "stookolie": answer, image: getImage(answerIndex)}, "7");
    }   

    const openInformationModal = () => {
        setModalState(true);
    }

    return(
        <div className = "w-full flex justify-center box-border">
            <div className = "container mx-auto flex-col justify-center box-border px-[16px] md:px-16 lg:px-32">
                <TitleBar back_text = {pageData?.back_text} infoColor = {globals?.colors?.default} title = {pageData?.title} description = {pageData?.description} goBack = {goBack} openInfoModal={openInformationModal} />
                <div className = "flex flex-col w-full mt-[10px] mb-[100px]">
                    <div className = "flex w-full flex-col md:flex-row border-b-[1px] border-b-[#F0F5F5]">
                        <Box flex = {1} hasRightBorder text = "Kachels" hoverValue = {1} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {GasOne} answerIndex = "10" isActive = {activeBox === "0"} />
                        <Box flex = {1} hasRightBorder text = "Ketel ouder dan 15 jaar" hoverValue = {2} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {GasTwo} answerIndex = "11" isActive = {activeBox === "1"} />
                        <Box flex = {1} hasRightBorder text = "Condensatieketel" hoverValue = {3} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {GasThree} answerIndex = "12" isActive = {activeBox === "2"} />
                        <Box flex = {1} hasRightBorder text = "Hybride ketel" hoverValue = {4} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {GasFour} answerIndex = "13" isActive = {activeBox === "3"} />
                    </div>
                    <div className = "flex w-full flex-col md:flex-row border-b-[1px] border-b-[#F0F5F5]">
                        <div className = "flex flex-1" />
                        <div className = "flex flex-1" />
                    </div>
                </div>
            </div>
            <InfoModal modalInformation = {modalInformation} isModalOpen = {modalState} closeModal = {() => setModalState(false)} />
        </div>
    )
}

export default Stookolie;