export function getLocalStateValue(key: string): any {
  const localStateString = localStorage.getItem("localState");

  if (localStateString) {
    try {
      const localStateObject = JSON.parse(localStateString);
      return localStateObject[key];
    } catch (error) {
      console.error("Error parsing localState JSON string:", error);
    }
  }
  return undefined;
}

const primaryLangField = 'nl'
const secondaryLangFields = ['en', 'de', 'fr']

const getName = (obj: any) => {
  let name = obj ? obj[primaryLangField] : null
  if (!name && obj) {
    secondaryLangFields.map((lang) => {
      if (obj[lang]) {
        name = obj[lang]
        return name
      }
    })
  }
  return name
}

export const formatAddress = (city: any, street: any) => {
  const cityName = getName(city)
  const streetName = getName(street)

  return `${streetName || ''} ${street?.number || ''}${street?.box ? "/" + street?.box : ''}, ${cityName || ''} ${street?.zipCode || ''}`
}

export const formatDate = (date: string) => {
  const dateObj = new Date(date)
  const dateFormated = `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`
  return dateFormated
}

export const formatTime = (dateStr: string) =>{
  const date = new Date(dateStr)
  var hours = date.getHours();
  var minutes = date.getMinutes() ;
  
  const _minutes = minutes < 10 ? '0' + minutes : minutes;
  const _hours = hours < 10 ? '0' + hours : hours
  return _hours + ':' + _minutes;
}

export const formatDateTime = (dateStr: string) => {

  const timeOnlyStr = formatTime(dateStr)
  const dateOnlyStr = formatDate(dateStr)
  return  [dateOnlyStr, timeOnlyStr].join(' ')

}
