import React, { FC, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useCookieConsent } from "use-cookie-consent";

import Header from "./header";
import Footer from "./footer";
import Cookies from "./cookies";

interface LayoutInterface {
    children: JSX.Element;
    disableFooter?: boolean;
}

const Layout: FC<LayoutInterface> = ({ children, disableFooter = false }) => {
    const location = useLocation();
    const { consent, acceptAllCookies, acceptCookies } = useCookieConsent();
    const [cookieBanner, setCookieBanner] = useState("");

    // useEffect for cookies
    useEffect(() => {
        if (Object.keys(consent).length > 0) {
            setCookieBanner("false");
        } else {
            setCookieBanner("true");
        }
    }, [consent]);


    return (
        <div className="flex flex-col h-screen">
            <Header />
            <main className="bg-[#fff] z-[5]">
                {children}
            </main>
            {!disableFooter && (
                <Footer />
            )}

            {cookieBanner === "true" && (
                <Cookies
                    setCookieBanner={setCookieBanner}
                    acceptAllCookies={acceptAllCookies}
                    acceptCookies={acceptCookies}
                />
            )}
        </div>

    );
};

export default Layout;
