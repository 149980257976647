import React, { FC, useContext, useState } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { useLocation } from "react-router-dom";
import house from "./../../assets/homepage/image.png";
import { SessionsUpdate } from "../../utils/session";
// pages
import { useCookies } from "react-cookie";
import EnergiescanAanvragen from "../modals/energiescanAanvragen";
import CallMeBack from "../modals/callMeBack";

interface RoutesInterface {}

/**
 * routes page to define all pages routes here
 */

interface SectionFormInterface {}

const SectionForm: FC<SectionFormInterface> = () => {
  const location = useLocation();
  // global state
  const { state, dispatch }: any = useContext(GlobalContext);
  const [cookies, setCookies] = useCookies();
  const [openEnergiescanAanvragen, setOpenEnergiescanAanvragen] =
    useState(false);
  const [openCallMeBack, setOpenCallMeBack] = useState(false);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const adress = event?.target[0]?.value;
    if (adress) {
      SessionsUpdate(
        state.session.id,
        { address: event?.target[0]?.value },
        cookies,
        setCookies,
        dispatch
      );
    } else {
      SessionsUpdate(
        state?.session?.id,
        { address: null },
        cookies,
        setCookies,
        dispatch
      );
    }
  };

  return (
    <section className="mx-[2%] lg:mx-0 px-[16px] lg:px-[42px] pt-5">
      <div className="md:flex">
        <div className="relative">
          <div className="flex flex-col xl:flex-row justify-between w-full text-left ">
            {/* left side */}
            <div className="w-full rounded-[40px] w-full xl:w-[58%] bg-[#e5f7f6] relative">
              <div className="w-full relative">
                <div className="p-[24px] md:pt-[50px] lg:pt-[94px] lg:px-[98px] lg:pb-[60px]">
                  <div className="w-full ">
                    <h1 className="text-[50px] lg:text-[70px] font-bold lg:leading-[96px] text-[#222222]">
                      Is je woning klaar voor morgen?
                    </h1>
                    <p className="mt-3 text-[20px] lg:text-[24px] leading-[32px] text-[#222222]">
                      Wil je besparen op energie of op water? Of heb je het te
                      warm in de zomer? Is je dak aan vervanging toe? Jij
                      bepaalt wat je belangrijk vindt. Wij zeggen wat het
                      opbrengt en hoeveel het zal kosten. Handig toch?
                    </p>
                    <p className="mt-8 text-[20px] lg:text-[24px] leading-[32px] text-[#222222] italic">
                      Je kan zelf een handig rapportje generen door enkele
                      gegevens in te vullen in deze handige online tool ‘Check
                      je huis’.
                    </p>
                    <div className="flex flex-col lg:flex-row lg:items-center mt-[56px]">
                      <button
                        type="submit"
                        className="transition ease-in-out hover:-translate-y-1 hover:scale-110 text-[20px] lg:text-[24px] w-full lg:w-auto text-white bg-[#00AFAA] px-[32px] py-[24px] rounded-[41px]"
                        onClick={() => {
                          setOpenEnergiescanAanvragen(true);
                        }}
                      >
                        Energiecoach aanvragen
                      </button>
                      <button
                        type="submit"
                        className="transition ease-in-out mt-8 lg:mt-0 hover:-translate-y-1 hover:scale-110 lg:ml-8 text-[20px] lg:text-[24px] w-full lg:w-auto text-[#222222] bg-transparent border-2 border-[#222222] px-[32px] py-[24px] rounded-[41px]"
                        onClick={handleSubmit}
                      >
                        Check Je Huis Peer
                      </button>
                    </div>
                    <p className="mt-[24px] text-[24px] leading-[32px] text-[#222222]">
                      We doen ons best om ‘Check je huis’ up to date te houden.
                      De energieprijzen in de tool worden maandelijks
                      geactualiseerd. Hou er rekening mee dat de gesimuleerde
                      besparing berekend wordt met de prijzen op het moment van
                      simulatie.
                    </p>
                    <p className="mt-[32px] text-[24px] leading-[32px] text-[#222222]">
                      Deelnemers dienen zich nergens toe te verbinden en kunnen
                      in elke fase stoppen en/of op eigen initiatief de stappen
                      verderzetten.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* right side */}
            <div className="w-full xl:w-[40%] h-auto relative mt-6 xl:mt-0 ">
              <div className="h-full relative">
                <img src={house} alt="" className="h-full rounded-[40px]" />
                <div className="relative flex justify-center px-[24px]">
                  <button
                    type="submit"
                    className="absolute bottom-[40px] lg:w-fit transition ease-in-out mt-8 lg:mt-0 hover:-translate-y-1 hover:scale-110 text-[20px] lg:text-[24px] lg:w-auto text-[#FFFFFF] bg-transparent border-2 border-[#FFFFFF] px-[32px] py-[24px] rounded-[41px] w-5/6"
                    onClick={() =>
                      window.open(
                        "https://planeetpeer.be/toekomst-ready-wonen/"
                      )
                    }
                  >
                    cfr. Toekomst ready wonen - Planeet Peer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CallMeBack open={openCallMeBack} setOpen={setOpenCallMeBack} />

      <EnergiescanAanvragen
        open={openEnergiescanAanvragen}
        setOpen={setOpenEnergiescanAanvragen}
      />
    </section>
  );
};

export default SectionForm;
