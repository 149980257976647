import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TitleBar from "../titleBar";
import Box from "../box";
import { getLocalStateValue } from "../../../utils/helpers";
import InfoModal from "../infoModal";

// image imports
import EnergyThree from "../../../assets/energy/energy_three.png"
import EnergyFour from "../../../assets/energy/energy_four.png"

type DigitalemeterProps = {
    globals?: {
        colors: {
            default: ""
        }
    },
    pageData?: {
        back_text: string,
        title: string,
        description: string,
        next_url: string,
        prev_url: string
    },
    updateAnswer?: (title: string, answer: string, url?: string) => void,
    storeAnswer?: (data: any, key: string) => void,
    modalInformation?: any
}

const Digitalemeter = ({ globals, pageData, updateAnswer = () => {}, storeAnswer = () => {}, modalInformation }: DigitalemeterProps) => {
    const [ hoverState, setHoverState ] = useState(0);
    const [ activeBox, setActiveBox ] = useState("");
    const [ modalState, setModalState ] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const localState = getLocalStateValue("25");
        if (localState) {
            setActiveBox(localState.answerNo);
        }
    }, [])

    const generateBackButtonText = () => {
        const question22State = getLocalStateValue("22");
        if(question22State.answerNo === "0") {
            return pageData?.back_text;
        }
        else {
            return "Zonnepanelen"
        }
    }

    const goBack = () => {
        const question22State = getLocalStateValue("22");
        if(question22State.answerNo === "0") {
            navigate(pageData?.prev_url || "")
        }
        else {
            navigate("/vraag/zonnepanelen")
        }
    }

    const getImage = (answerIndex?: string) => {
        switch(answerIndex) {
            case "0":
                return EnergyThree;
            case "1":
                return EnergyFour;
        }
    }
    
    const onClick = (answer?: string, answerIndex?: string) => {
        const question = "digitale_meter";
        updateAnswer(question || "", answer || "", pageData?.next_url);
        storeAnswer({"answerNo": answerIndex, "digitale_meter": answer, image: getImage(answerIndex)}, "25")
    }

    const openInformationModal = () => {
        setModalState(true);
    }

    return(
        <div className = "w-full flex justify-center box-border">
            <div className = "container mx-auto flex-col justify-center box-border px-[16px] md:px-16 lg:px-32">
                <TitleBar back_text = {generateBackButtonText()} infoColor = {globals?.colors?.default} title = {pageData?.title} description = {pageData?.description} goBack = {goBack} openInfoModal={openInformationModal} />
                <div className = "flex flex-col w-full mt-[10px] mb-[100px]">
                    <div className = "flex w-full flex-col md:flex-row">
                        <Box flex = {1} hasRightBorder text = "Wel een digitale meter" hoverValue = {1} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {EnergyThree} answerIndex = "0" isActive = {activeBox === "0"} />
                        <Box flex = {1} hasRightBorder text = "Geen digitale meter" hoverValue = {2} hoverState = {hoverState} onHoverEvent = {setHoverState} color = {globals?.colors?.default} onRoute = {onClick} image = {EnergyFour} answerIndex = "1" isActive = {activeBox === "1"} />
                        <div className = "flex flex-1" />
                    </div>
                </div>
            </div>
            <InfoModal modalInformation = {modalInformation} isModalOpen = {modalState} closeModal = {() => setModalState(false)} />
        </div>
    )
}

export default Digitalemeter;