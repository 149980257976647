import React from "react";

type SubmitBoxProps = {
    buttonColor?: string,
    buttonText?: string,
    children?: React.ReactNode,
    onSubmit?: () => void;
    isButtonActive?: boolean,
}

const SubmitBox = ({ buttonColor = "#00AFAA", buttonText = "Volgende", children, onSubmit, isButtonActive = false}: SubmitBoxProps ) => {

    return(
        <div className = "w-full flex flex-col justify-end bg-white">
            {children}
            <div className = "w-full flex justify-end">
                <button type = "button" className = {`w-[130px] h-[60px] flex justify-center items-center mr-[25px] my-[15px]`} disabled = {!isButtonActive} style = {{backgroundColor: isButtonActive ? buttonColor : "grey"}} onClick = {onSubmit} >
                    <p className = "font-sans font-bold text-[18px] text-white">{buttonText}</p>
                </button>
            </div>
        </div>
    )
}

export default SubmitBox;